import moment from "moment";
import jwt_decode from "jwt-decode";
import { API } from "utils/api";

// Action
export const AUTHENTICATION_REQUEST = () => {
  return {
    type: "AUTHENTICATION_REQUEST",
    authRequested: true
  };
};

export const AUTHENTICATION_SUCCESS = () => {
  return {
    type: "AUTHENTICATION_SUCCESS",
    isAuthenticated: true,
    authRequested: false,
    message: ""
  };
};

export const AUTHENTICATION_FAILED = message => {
  return {
    type: "AUTHENTICATION_FAILED",
    isAuthenticated: false,
    authRequested: false,
    preAuthRequest: false,
    message: message
  };
};

export const AUTHENTICATION_RESET = () => {
  return {
    type: "AUTHENTICATION_RESET",
    isAuthenticated: false
  };
};

export const AUTHENTICATION_VALIDATE = (isValid, message = "") => {
  return {
    type: "AUTHENTICATION_VALIDATE",
    isAuthenticated: isValid,
    message: message
  };
};

export const AUTHENTICATION_USERINFO = userInfo => {
  return {
    type: "AUTHENTICATION_USERINFO",
    userInfo: userInfo
  };
};

export const AUTHENTICATION_PREAUTH = (companies, result) => {
  return {
    type: "AUTHENTICATION_PREAUTH",
    companies: companies,
    isPreAuth: result,
    preAuthRequest: false
  };
};

export const AUTHENTICATION_PREAUTH_REQUEST = state => {
  return {
    type: "AUTHENTICATION_PREAUTH_REQUEST",
    preAuthRequest: state
  };
};

// Functions

export const loginUser = (username, password, company, dispatch) => {
  dispatch(AUTHENTICATION_REQUEST());

  let authPayload = {
    username: username,
    password: password,
    company: company
  };

  return fetch(API + "/api/v1.0/Authentication", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(authPayload)
  })
    .then(response => {
      if (response.ok) {
        // Request success
        response.json().then(result => {
          if (result.access_token) {
            // Authentication success
            var token = jwt_decode(result.access_token);
            var userInfo = JSON.parse(token.userInfo);
            userInfo = {
              ...userInfo,
              userType: token.userType
            };
            // Validate if token is not expired
            if (new Date(1000 * token.exp) > new Date()) {
              localStorage.setItem("access_token", result.access_token);
              dispatch(AUTHENTICATION_USERINFO(userInfo));
              dispatch(AUTHENTICATION_SUCCESS());
            }
          } else {
            // Authentication failed
            dispatch(AUTHENTICATION_FAILED(result.message));
          }
        });
      } else {
        // Request failed
        response.json().then(result => {
          dispatch(AUTHENTICATION_FAILED(result.message));
        });
      }
    })
    .catch(error => {
      dispatch(AUTHENTICATION_FAILED("No connection to host"));
    });
};

export const preLoginUser = (username, password, dispatch) => {
  let authPayload = {
    username: username,
    password: password
  };

  dispatch(AUTHENTICATION_PREAUTH_REQUEST(true));
  fetch(API + "/api/v1.0/Authentication/PreAuth", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(authPayload)
  })
    .then(response => {
      if (response.ok) {
        // Request success

        response.json().then(result => {
          if (result.length >= 1) {
            dispatch(AUTHENTICATION_PREAUTH(result, true));
            return true;
          } else {
            // Authentication failed
            dispatch(AUTHENTICATION_FAILED(result.message));
            return false;
          }
        });
      } else {
        // Request failed
        dispatch(AUTHENTICATION_PREAUTH([], false));
        dispatch(AUTHENTICATION_FAILED(response.statusText));
        return false;
      }
    })
    .catch(error => {
      dispatch(AUTHENTICATION_FAILED("No connection to host"));
      return false;
    });
};

export const logoutUser = dispatch => {
  localStorage.removeItem("access_token");
  dispatch(AUTHENTICATION_RESET());
};

export const validateUser = dispatch => {
  // Validate token
  let token = localStorage.getItem("access_token");
  if (token) {
    if (isValid(token)) {
      let userInfo = JSON.parse(jwt_decode(token).userInfo);
      userInfo = {
        ...userInfo,
        userType: jwt_decode(token).userType
      };
      if (userInfo) {
        dispatch(AUTHENTICATION_USERINFO(userInfo));
      }
      dispatch(AUTHENTICATION_VALIDATE(true));
    } else {
      localStorage.removeItem("access_token");
      dispatch(AUTHENTICATION_VALIDATE(false));
    }
  } else {
    dispatch(AUTHENTICATION_VALIDATE(false));
  }
};

// Helper functions

function isValid(token) {
  let _token = jwt_decode(token);

  if (!token) {
    return false;
  }
  tokenExpireDate(token);
  return new Date(1000 * _token.exp) > new Date();
}

export function tokenExpireDate(token) {
  let _token = jwt_decode(token);
  if (!_token) {
    return null;
  }
  let current = moment();
  let expireTime = moment.unix(_token.exp);
  let duration = moment.duration(expireTime.diff(current));
  return duration;
}
