import React, { Component } from "react";
import { Modal } from "office-ui-fabric-react/lib/Modal";
import {
  DefaultButton,
  PrimaryButton
} from "office-ui-fabric-react/lib/Button";
import { TextField } from "office-ui-fabric-react/lib/TextField";
import { Dropdown } from "office-ui-fabric-react/lib/Dropdown";

import _ from "lodash";
import * as helpers from "utils/functions";
import { API } from "utils/api";
import "./NewGroupForm.css";

export default class NewGroupForm extends Component {
  constructor(props) {
    super(props);

    this.options = [
      {
        key: 0,
        text: this.props.strings.me
      },
      {
        key: 1,
        text: this.props.strings.all
      }
    ];

    this.initialState = {
      title: "",
      description: "",
      visibility: 0
    };

    this.state = this.initialState;
  }

  componentDidMount = () => {};

  componentWillUpdate = (nextProps, nextState) => {};

  render() {
    const { strings, unitItems } = this.props;
    return (
      <Modal
        containerClassName="NewGroupFormModalContainer ms-scaleUpIn100"
        isOpen={this.props.isOpen}
        onDismiss={this.props.onDismiss}
        isBlocking={false}
      >
        <div className="NewGroupFormContainer ">
          <div className="NewGroupFormHeader">
            <font className="ms-font-xxl">{strings.saveGroup}</font>
          </div>
          <div className="NewGroupFormContent container">
            <div className="row">
              <div className="col">
                <TextField
                  label={strings.name}
                  iconProps="ms-Icon--Permissions ms-Icon"
                  type="text"
                  onGetErrorMessage={value => this.validateInput(value, "name")}
                  onChanged={value => this.setState({ title: value })}
                  validateOnFocusOut={true}
                  validateOnLoad={false}
                />

                <TextField
                  style={{ height: "100px" }}
                  label={strings.description}
                  iconProps="ms-Icon--Permissions ms-Icon"
                  type="text"
                  // onGetErrorMessage={(value) => this.validateInput(value, "description")}
                  validateOnFocusOut={true}
                  validateOnLoad={false}
                  onChanged={value => this.setState({ description: value })}
                  multiline
                />

                <div
                  style={{
                    marginTop: "10px",
                    marginBottom: "15px"
                  }}
                >
                  {strings.selectedUnits}

                  <div
                    style={{
                      marginTop: "5px",
                      marginLeft: "10px",
                      maxHeight: "30vh",
                      overflowY: "auto"
                    }}
                  >
                    <table>
                      <tbody>
                        {this.props.selectedUnits.map((unit, ix) => {
                          const unitIndex = _.findIndex(unitItems, o => {
                            return o.radioNumber === unit;
                          });
                          const currentUnit = unitItems[unitIndex];

                          if (!currentUnit) {
                            return null;
                          }

                          return (
                            <tr key={ix}>
                              <td style={{ textAlign: "right" }}>{`${
                                currentUnit.radioNumber
                              } `}</td>
                              <td>{`- ${currentUnit.enhKatText} `}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>

                <Dropdown
                  label={strings.visible}
                  options={this.options}
                  onChanged={value => this.setState({ visibility: value.key })}
                  defaultSelectedKey={0}
                />
              </div>
            </div>
          </div>
          <div className="NewGroupFormFooter">
            <DefaultButton
              className="NewGroupFormButton"
              description=""
              onClick={this.onDismiss}
              text={strings.abort}
            />
            <PrimaryButton
              className="NewGroupFormButton"
              description=""
              onClick={this.saveGroup}
              text={strings.save}
            />
          </div>
        </div>
      </Modal>
    );
  }
  saveGroup = () => {
    let url = this.state.description
      ? `${API}/api/v1.0/Groups/Create/${this.state.visibility}/${
          this.state.title
        }/${this.state.description}`
      : `${API}/api/v1.0/Groups/Create/${this.state.visibility}/${
          this.state.title
        }`;

    return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("access_token")
      },
      body: JSON.stringify(this.props.selectedUnits)
    })
      .then(response => {
        if (response.ok) {
          this.props.loadUserGroups();
          this.onDismiss();
        } else {
          // Request failed
          response.text().then(result => {
            helpers.notifyGenericError(this.props.strings, result);
          });
        }
      })
      .catch(error => {});
  };

  validateInput = (value, inputType) => {
    return "";
  };

  onDismiss = () => {
    this.setState(this.initialState);
    this.props.onDismiss();
  };
}
