import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import LayoutContainer from "./containers/LayoutContainer";
import { store } from "store";
import moment_locale_sv from "moment/locale/sv";

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <LayoutContainer />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

if (module.hot) {
  module.hot.accept();
}

//<LayoutContainer/>
