import * as React from "react";
import translate from "i18n/Translate";

import { Panel, PanelType } from "office-ui-fabric-react/lib/Panel";
import { Dropdown } from "office-ui-fabric-react/lib/Dropdown";
import { PrimaryButton } from "office-ui-fabric-react/lib/Button";
import { VERSION } from "version";
import "./userSettings.css";

var languages = require("../../i18n/languages.json");

class UserSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: { key: this.props.currentLanguage, text: "" }
    };
  }

  closeDialog = () => {
    // this.props.toggleUserSettings();
  };

  selectLanguage = item => {
    this.props.onLanguageChange(item.key);
  };

  logoutUser = () => {
    this.props.onLogout();
  };

  render() {
    const { strings } = this.props;
    const selectedItem = {
      key: this.props.currentLanguage,
      text: ""
    };
    return (
      <div>
        <Panel
          isBlocking={false}
          isOpen={this.props.isOpen}
          onDismiss={this.props.onDismiss}
          type={PanelType.smallFixedFar}
          headerText={strings.headerText}
          closeButtonAriaLabel="Close"
          isFooterAtBottom={true}
          onRenderFooterContent={this.renderFooterContent}
        >
          <Dropdown
            label={strings.userSettingsHeaderText}
            selectedKey={selectedItem && selectedItem.key}
            onChanged={item => this.selectLanguage(item)}
            options={languages}
          />

          <p>
            <PrimaryButton
              text={this.props.strings.logout}
              onClick={this.logoutUser}
            />
          </p>
        </Panel>
      </div>
    );
  }

  renderFooterContent = () => {
    return <div>v{VERSION}</div>;
  };
}

export default translate()(UserSettings);
