import * as React from "react";
import Flexbox from "flexbox-react";
import {
  Persona,
  PersonaSize,
  PersonaPresence
} from "office-ui-fabric-react/lib/Persona";
import UserSettings from "../UserSettings/UserSettings";
export default class MenuUserItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isUserPanelOpen: false
    };
  }

  openUserPanel = () => {
    this.setState({
      isUserPanelOpen: true
    });
  };

  closeUserPanel = () => {
    this.setState({
      isUserPanelOpen: false
    });
  };

  render() {
    this.loggedInUser = {
      text: this.props.user.UserName
    };
    const style = {
      marginLeft: "20px"
    };
    if (this.props.isAuthenticated) {
      return (
        <Flexbox
          className={"right-aligned ms-bgColor-neutralLight ms-fontColor-black"}
          style={{ paddingLeft: "10px" }}
        >
          <UserSettings
            isOpen={this.state.isUserPanelOpen}
            onClick={this.openUserPanel}
            onDismiss={this.closeUserPanel}
            onLogout={this.props.onLogout}
            onLanguageChange={this.props.onLanguageChange}
          />

          {!this.props.children ? (
            <div className="menu-center-vertical-aligned ms-font-m userinfo">
              <Persona
                {...this.loggedInUser}
                size={PersonaSize.extraExtraSmall}
                presence={PersonaPresence.none}
                hidePersonaDetails={false}
              />
            </div>
          ) : (
            <div
              className="menu-center-vertical-aligned ms-font-m "
              style={style}
              onClick={this.props.onClick}
            >
              {this.props.children}
            </div>
          )}
          <div
            className="ms-bgColor-neutralTertiaryAlt--hover"
            onClick={this.openUserPanel}
          >
            <i
              className="menu-center-vertical-aligned ms-font-xs ms-Icon ms-Icon--CaretDownSolid8"
              style={{ marginLeft: "10px", marginRight: "10px" }}
              aria-hidden="true"
            />
          </div>
        </Flexbox>
      );
    } else {
      return null;
    }
  }
}
