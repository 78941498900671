import React, { Component } from "react";
import * as helpers from "utils/functions";
import Card from "components/Card/Card";
import { PrimaryButton } from "office-ui-fabric-react";
import GoogleAddressSearch from "components/GoogleAddressSearch/GoogleAddressSearch";
import CoordinatesSearch from "components/CoordinatesSearch/CoordinatesSearch";

export default class Search extends Component {
  constructor(props) {
    super(props);
    this.googleAddressSearch = React.createRef();
    this.coordinatesSearch = React.createRef();
  }
  render() {
    const { strings } = this.props;
    return (
      <div>
        <Card header={helpers.Cap(strings.search)}>
          <GoogleAddressSearch
            ref={this.googleAddressSearch}
            callback={this.props.setAddressSearchObject}
            onSavePOICallback={this.props.setAddressCreatePoiCallback}
            label={helpers.Cap(strings.searchAddress)}
            buttonText={helpers.Cap(strings.search)}
            strings={strings}
          />

          <CoordinatesSearch
            ref={this.coordinatesSearch}
            callback={this.props.setAddressSearchObject}
            onSavePOICallback={this.props.setAddressCreatePoiCallback}
            strings={strings}
            updateUnitLocation={this.props.updateUnitLocation}
          />

          <div style={{ textAlign: "right", marginTop: "10px" }}>
            <PrimaryButton text="Clear" onClick={this.onClear} />
          </div>
        </Card>
      </div>
    );
  }
  onClear = () => {
    this.props.clearAddressSearchObject &&
      this.props.clearAddressSearchObject();
    this.props.clearUnitLocation && this.props.clearUnitLocation();

    this.googleAddressSearch.current.onClear();
    this.coordinatesSearch.current.onClear();
  };
}
