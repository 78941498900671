import { API } from "utils/api";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import translate from "i18n/Translate";
import { connect } from "react-redux";
import { mapDispatchToProps } from "actions/mapDispatchToProps";
import * as helpers from "utils/functions";
import { Modal } from "office-ui-fabric-react/lib/Modal";
import {
  DefaultButton,
  PrimaryButton,
  TextField,
  Dropdown
} from "office-ui-fabric-react";
import "./UserRegistrationForm.css";

const mapStateToProps = state => {
  return state;
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    translate()(
      class UserRegistrationForm extends Component {
        constructor(props) {
          super(props);

          this.state = {
            visible: true,
            username: "",
            displayEmployeeNumber: false,
            roles: [],
            errors: {
              akarNo: "",
              customerNo: "",
              general: "",
              username: "",
              company: "",
              email: "",
              name: "",
              phone: "",
              role: "",
              password: "",
              passwordRetype: ""
            }
          };
        }

        componentDidMount() {
          this.createCompanyDropdownOptions();
        }

        render() {
          const { strings } = this.props;
          return (
            <Modal
              containerClassName="UserRegistrationFormModalContainer"
              isOpen={this.state.visible}
              onDismiss={this.onDismiss}
              isBlocking={true}
              scrollableContentClassName="ModalContainerNoScroll"
            >
              <div className="UserRegistrationFormContainer ">
                <div className="UserRegistrationFormHeader">
                  <font className="ms-font-xxl">{strings.newUser}</font>
                </div>
                <form>
                  <div className="UserRegistrationFormContent">
                    <div className="row">
                      <div className="col-12">
                        <Dropdown
                          label={helpers.Cap(strings.companies)}
                          options={this.state.companies}
                          onChanged={this.onCompanyChange}
                          errorMessage={this.state.errors.company}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12">
                        <Dropdown
                          label={helpers.Cap(strings.role)}
                          options={this.state.roles}
                          onChanged={this.onRoleChange}
                          errorMessage={this.state.errors.role}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12">
                        <TextField
                          label={strings.usernameLabel}
                          type="text"
                          autoComplete="username"
                          onChanged={value =>
                            this.setState({ username: value })
                          }
                          errorMessage={this.state.errors.username}
                        />
                      </div>
                    </div>

                    {this.createRoleCustomField(this.state.role)}

                    <div className="row">
                      <div className="col-12">
                        <TextField
                          label={strings.emailLabel}
                          type="text"
                          autoComplete="email"
                          onChanged={value => this.setState({ email: value })}
                          errorMessage={this.state.errors.email}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-7">
                        <TextField
                          label={strings.name}
                          type="text"
                          autoComplete="name"
                          onChanged={value => this.setState({ name: value })}
                          errorMessage={this.state.errors.name}
                        />
                      </div>
                      <div className="col-5">
                        <TextField
                          label={strings.phoneLabel}
                          type="text"
                          autoComplete="mobile"
                          onChanged={value => this.setState({ phone: value })}
                          errorMessage={this.state.errors.phone}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-6">
                        <TextField
                          label={strings.passwordLabel}
                          type="password"
                          autoComplete="new-password"
                          onChanged={value =>
                            this.setState({ password: value })
                          }
                          errorMessage={this.state.errors.password}
                        />
                      </div>

                      <div className="col-6">
                        <TextField
                          label={strings.passwordRetypeLabel}
                          type="password"
                          autoComplete="new-password"
                          onChanged={value =>
                            this.setState({ password_retype: value })
                          }
                          errorMessage={this.state.errors.passwordRetype}
                        />
                      </div>
                    </div>
                    {this.state.errors.general && (
                      <div className="row">
                        <div
                          className="col-12 ms-fontColor-redDark"
                          style={{ marginTop: "15px" }}
                        >
                          {this.state.errors.general}
                        </div>
                      </div>
                    )}
                  </div>
                </form>
                <div className="UserRegistrationFormFooter">
                  <DefaultButton
                    className="UserRegistrationFormButton"
                    description=""
                    onClick={this.onDismiss}
                    text={strings.abort}
                  />
                  <PrimaryButton
                    className="UserRegistrationFormButton"
                    description=""
                    text={strings.save}
                    onClick={this.saveUser}
                  />
                </div>
              </div>
            </Modal>
          );
        }

        createRoleCustomField = rolePk => {
          const { strings } = this.props;

          switch (parseInt(rolePk, 10)) {
            case 3: // Bruker
              return (
                <div className="row">
                  <div className="col-12">
                    <TextField
                      label={helpers.Cap(strings.employeeNumber)}
                      type="text"
                      onChanged={value => this.setState({ employeeNo: value })}
                    />
                  </div>
                </div>
              );

            case 4: // Fører
            case 5: // Fører
            case 7: // Fører
              return (
                <div className="row">
                  <div className="col-12">
                    <TextField
                      label={helpers.Cap(strings.driverNumber)}
                      type="text"
                      onChanged={value => this.setState({ akarNo: value })}
                      errorMessage={this.state.errors.akarNo}
                    />
                  </div>
                </div>
              );

            case 6: // Kunde
              return (
                <div className="row">
                  <div className="col-12">
                    <TextField
                      label={helpers.Cap(strings.customerNumber)}
                      type="text"
                      onChanged={value => this.setState({ customerNo: value })}
                      errorMessage={this.state.errors.customerNo}
                    />
                  </div>
                </div>
              );

            default:
              return null;
          }
        };

        saveUser = () => {
          var regexIsNotNumber = /[^0-9]/g;
          const errors = [];
          let isValid = true;

          if (!this.state.company) {
            errors.company = this.props.strings.errorMessageMissingCompany;
            isValid = false;
          }

          if (!this.state.role) {
            errors.role = this.props.strings.errorMessageMissingRole;
            isValid = false;
          }

          if (!this.state.username) {
            errors.username = this.props.strings.errorMessageMissingUsername;
            isValid = false;
          }

          if (!this.state.email) {
            errors.email = this.props.strings.errorMessageMissingEmail;
            isValid = false;
          }

          if (!this.state.name) {
            errors.name = this.props.strings.errorMessageMissingName;
            isValid = false;
          }

          if (!this.state.phone) {
            errors.phone = this.props.strings.errorMessageMissingPhoneNo;
            isValid = false;
          }

          if (!this.state.password) {
            errors.password = this.props.strings.errorMessageMissingPassword;
            isValid = false;
          }

          if (this.state.akarNo) {
            if (regexIsNotNumber.test(this.state.akarNo)) {
              errors.akarNo = this.props.strings.errorMessageMissingAkarNo;
              isValid = false;
            }
          }

          if (this.state.customerNo) {
            if (regexIsNotNumber.test(this.state.customerNo)) {
              errors.customerNo = this.props.strings.errorMessageMissingCustomerNo;
              isValid = false;
            }
          }

          if (!this.state.password) {
            errors.password = this.props.strings.errorMessageMissingPassword;
            isValid = false;
          }

          if (this.state.password !== this.state.password_retype) {
            errors.passwordRetype = this.props.strings.errorMessagePasswordMismatch;
            isValid = false;
          }

          if (!isValid) {
            this.setState({ errors });
            return;
          }

          const newUser = {
            username: this.state.username,
            email: this.state.email,
            name: this.state.name,
            password: this.state.password,
            phoneNo: this.state.phone,
            akarNo: this.state.akarNo,
            customerNo: this.state.customerNo,
            employeeNo: this.state.employeeNo,
            roleList: [
              {
                rolePk: this.state.role
              }
            ],
            companyList: [
              {
                CompanyPk: this.state.company
              }
            ]
          };

          return fetch(API + "/api/v1.0/Administrator/Create", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "bearer " + localStorage.getItem("access_token")
            },
            body: JSON.stringify(newUser)
          })
            .then(response => {
              if (response.ok) {
                // Request success
                this.onDismiss();
                this.props.onSuccessCallback();
              } else {
                // Request failed
                response.json().then(result => {
                  helpers.notifyGenericError(
                    this.props.strings,
                    result.Message
                  );
                  this.setState({ error: result.Message });
                });
              }
            })
            .catch(error => {
              this.setState({ error: error });
            });
        };

        onCompanyChange = value => {
          this.getCurrentRoles(value.key);
          this.setState({ company: value.key });
        };

        onRoleChange = value => {
          this.setState({ role: value.key });
        };

        onDismiss = () => {
          this.setState({ visible: false });
          this.props.toggleUserAdministration(true);
        };

        createCompanyDropdownOptions = () => {
          const dropdownOptions = [];

          fetch(API + "/api/v1.0/User/Get/UserCompanies", {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "bearer " + localStorage.getItem("access_token")
            }
          }).then(response => {
            if (response.ok) {
              // Request success
              response.json().then(result => {
                result.forEach(element => {
                  dropdownOptions.push({
                    key: element.companyPk,
                    text: element.companyName
                  });
                });
                this.setState({ companies: dropdownOptions });
              });
            } else {
              // Request failed
              response.json().then(result => {});
            }
          });
        };

        getCurrentRoles = value => {
          fetch(API + `/api/v1.0/User/Get/UserRoles/${value}`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "bearer " + localStorage.getItem("access_token")
            }
          }).then(response => {
            if (response.ok) {
              // Request success
              response.json().then(result => {
                const roleElements = [];
                result.forEach(element => {
                  roleElements.push({
                    key: element.rolePk,
                    text: element.roleName
                  });
                });
                this.setState({ roles: roleElements });
              });
            } else {
              // Request failed
              response.json().then(result => {});
            }
          });
        };
      }
    )
  )
);
