import * as React from "react";
import { Helmet } from "react-helmet";
import translate from "i18n/Translate";
import { TextField } from "office-ui-fabric-react/lib/TextField";
import * as helpers from "utils/functions";
import LoadingButton from "components/LoadingButton";
import { Dropdown } from "office-ui-fabric-react/lib/Dropdown";
import * as APPVAR from "utils/constants";
import { VERSION } from "version.js";
import "styles/login.css";

/**
 * Login component displaying loginform on entry
 *
 * @export
 * @class LoginForm
 * @extends {React.Component}
 */
export class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    // this.passwordInput = undefined;
    this.username = this.password = "";
    this.companies = [];
    this.company = undefined;
    this.state = {
      username: undefined,
      password: undefined
    };
  }

  loginUser = (username, password, company) => {
    if (username && password && company) {
      this.setState((state, props) => {
        return { username: username, password: password, company: company };
      });

      if (username && password) {
        this.props.loginAction(username, password, company);
      }
    } else {
    }
  };

  onPasswordBlur = e => {
    if (e.target.value !== "" && !this.props.isPreAuth) {
      this.props.preLoginAction(this.username, this.password);
    }
  };

  onPasswordOnChanged = e => {
    this.password = e;
  };

  onCompanyOnChanged = e => {
    this.company = e.key;
  };

  showLoadingButton = () => {
    const { strings } = this.props;
    if (this.props.preAuthRequest) {
      return (
        <LoadingButton isLoading={true} disabled={true}>
          {strings.loadingCompanies}
        </LoadingButton>
      );
    }

    if (this.props.authRequested) {
      return (
        <LoadingButton isLoading={true} disabled={true}>
          {strings.loginInProgress}
        </LoadingButton>
      );
    }

    return (
      <LoadingButton
        callback={() => {
          if (this.props.isPreAuth) {
            this.loginUser(this.username, this.password, this.company);
          } else {
            this.props.preLoginAction(this.username, this.password);
          }
        }}
      >
        {strings.login}
      </LoadingButton>
    );
  };

  render() {
    const { strings, companies } = this.props;
    let companyLabel = strings.noCompaniesApplicable;
    if (companies && companies.length > 0 && this.props.isPreAuth) {
      companyLabel = strings.selectCompany;
      this.companies = [];
      companies.map((key, value) => {
        this.companies.push({ key: key.identifier, text: key.companyName });
        return this.companies;
      });
    }
    return (
      <div
        className="ms-Fabric container-fluid"
        style={{ backgroundColor: "#f4f4f4" }}
      >
        <Helmet>
          <title>Login - {APPVAR.APPLICATION_NAME}</title>
        </Helmet>
        <div className="row h-100 justify-content-around">
          <div className="col-9 hidden-lg-down ">
            <div className="row h-100 align-items-center backgroundimage" />
          </div>
          <div className="col-11 col-sm-6 col-md-6 col-lg-6 col-xl-3 blurred">
            <div className="row h-100 align-items-center justify-content-around">
              <div className="col-10">
                <div className="my-auto animated fadeIn">
                  <div className="login-box-formgroup ms-textAlignLeft ms-u-slideLeftIn40">
                    <div className="col ms-textAlignCenter ms-font-xxl ms-u-slideLeftIn40">
                      <div>
                        {APPVAR.APPLICATION_NAME}
                        <div className="ms-font-xs">
                          <p>{VERSION}</p>
                        </div>
                      </div>
                    </div>
                    <div className="row justify-content-around">
                      <div className="col-8 col-lg-10">
                        <TextField
                          label={strings.usernameLabel}
                          iconProps="ms-Icon--Mail ms-Icon"
                          value={this.state.username}
                          onChanged={value => (this.username = value)}
                          // onGetErrorMessage={this._getErrorMessage}
                        />

                        <TextField
                          label={strings.emailLabel}
                          iconProps="ms-Icon--Permissions ms-Icon"
                          type="password"
                          value={this.state.password}
                          errorMessage={this.props.errormessage}
                          // ref={ref => (this.passwordInput = ref)}
                          onBlur={value => this.onPasswordBlur(value)}
                          onChanged={value => this.onPasswordOnChanged(value)}
                          // onGetErrorMessage={this._getErrorMessage}
                        />

                        <div>
                          <Dropdown
                            placeHolder={helpers.Cap(companyLabel)}
                            label={strings.selectCompany}
                            id="CompanyDropDown"
                            options={this.companies}
                            onChanged={this.onCompanyOnChanged}
                          />
                        </div>

                        <div
                          className=" ms-textAlignCenter"
                          style={{ marginTop: 15 }}
                        >
                          {this.showLoadingButton()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default translate()(LoginForm);
