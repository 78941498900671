import React, { Component } from "react";
import * as h from "utils/functions";
import Card from "components/Card/Card";
import moment from "moment";
import { Checkbox } from "office-ui-fabric-react/lib/Checkbox";
import {
  TextField,
  MaskedTextField
} from "office-ui-fabric-react/lib/TextField";
import { DatePicker, DayOfWeek } from "office-ui-fabric-react/lib/DatePicker";
import { Label } from "office-ui-fabric-react/lib/Label";
import { Slider } from "office-ui-fabric-react/lib/Slider";
import { PrimaryButton, DefaultButton } from "office-ui-fabric-react";

export default class Tracking extends Component {
  constructor(props) {
    super(props);

    this.maskedFromTime = React.createRef();
    this.maskedToTime = React.createRef();
    this.initialState = {
      unitChecked: true,
      fromTime: "00:00",
      toTime: "00:00",
      trackingId: "",
      fromDate: new Date(
        moment()
          .subtract(1, "days")
          .format("L")
      ),
      toDate: new Date(moment().format("L")),
      interval: 5
    };

    this.state = this.initialState;
  }

  render() {
    const { strings } = this.props;
    return (
      <div>
        <Card header={h.Cap(strings.tracking)}>
          <div className="row">
            <div
              className="col"
              style={{ maxWidth: "100px", marginTop: "15px" }}
            >
              <Checkbox
                label={h.Cap(strings.unit)}
                checked={this.state.unitChecked}
                onChange={this.onCheckboxChange}
              />
            </div>
            <div
              className="col"
              style={{ maxWidth: "100px", marginTop: "15px" }}
            >
              <Checkbox
                label={h.Cap(strings.order)}
                checked={!this.state.unitChecked}
                onChange={this.onCheckboxChange}
              />
            </div>
          </div>

          <div className="row" style={{ marginTop: "5px" }}>
            <div className="col">
              <TextField
                id="rt90"
                label={strings.provideNumber}
                type="text"
                value={this.state.trackingId}
                onChange={this.onTrackingIdChange}
              />
            </div>
          </div>

          <div style={{ marginTop: "5px" }}>
            <Label>{strings.trackingInterval}</Label>
          </div>
          <div style={{ marginLeft: "15px" }}>
            <div className="row">
              <div className="col-6">
                <DatePicker
                  label={h.Cap(strings.fromDate)}
                  placeholder={strings.selectDate}
                  firstDayOfWeek={DayOfWeek.Monday}
                  showMonthPickerAsOverlay={true}
                  disableAutoFocus={true}
                  value={this.state.fromDate}
                  onSelectDate={this.onSelectFromDate}
                  formatDate={this.formatDate}
                />
              </div>
              <div className="col-6" style={{ maxWidth: "95px" }}>
                <MaskedTextField
                  ref={this.maskedFromTime}
                  label={h.Cap(strings.time)}
                  type="text"
                  mask="99:99"
                  value={this.state.fromTime}
                  onKeyUp={this.onFromTimeChange}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <DatePicker
                  label={h.Cap(strings.fromDate)}
                  placeholder={strings.selectDate}
                  firstDayOfWeek={DayOfWeek.Monday}
                  showMonthPickerAsOverlay={true}
                  disableAutoFocus={true}
                  value={this.state.toDate}
                  onSelectDate={this.onSelectToDate}
                  formatDate={this.formatDate}
                />
              </div>
              <div className="col-6" style={{ maxWidth: "95px" }}>
                <MaskedTextField
                  ref={this.maskedToTime}
                  label={h.Cap(strings.time)}
                  type="text"
                  mask="99:99"
                  value={this.state.toTime}
                  // onChange={this.onToTimeChange}
                  onKeyUp={this.onToTimeChange}
                />
              </div>
            </div>
          </div>
          <div style={{ marginTop: "5px" }}>
            <Label>{strings.positionInterval}</Label>
          </div>
          <div className="row">
            <div className="col">
              <Slider
                min={1}
                max={30}
                step={1}
                defaultValue={this.state.interval}
                showValue={true}
                onChange={this.onIntervalChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col" style={{ textAlign: "left" }}>
              <DefaultButton
                text={h.Cap(strings.clearTracking)}
                onClick={this.onClearClicked}
              />
            </div>
            <div className="col" style={{ textAlign: "right" }}>
              <PrimaryButton
                text={h.Cap(strings.track)}
                onClick={this.onSearchClicked}
                disabled={
                  !(
                    this.state.trackingId &&
                    this.state.interval &&
                    this.state.fromDate &&
                    this.state.toDate
                  )
                }
              />
            </div>
          </div>
        </Card>
      </div>
    );
  }

  onTrackingIdChange = (e, id) => {
    this.setState({ trackingId: id });
  };
  onClearClicked = () => {
    this.setState(this.initialState);
    this.props.clearTrackingHistory();
  };

  onSelectFromDate = value => {
    this.setState({ fromDate: value });
  };

  onSelectToDate = value => {
    this.setState({ toDate: value });
  };

  onToTimeChange = value => {
    this.setState({ toTime: value });
  };

  onIntervalChange = value => {
    this.setState({ interval: value });
  };

  onCheckboxChange = unitChecked => {
    this.setState({
      unitChecked:
        this.state.unitChecked && unitChecked ? !unitChecked : unitChecked
    });
  };

  onFromTimeChange = (e, value) => {
    value = e.target.value;
    const fromTime = this.validateInputTime(value);

    this.maskedFromTime.current.setValue(fromTime);
    this.setState({ fromTime });
  };

  onToTimeChange = (e, value) => {
    value = e.target.value;
    const toTime = this.validateInputTime(value);

    this.maskedToTime.current.setValue(toTime);
    this.setState({ toTime });
  };

  onSearchClicked = () => {
    if (
      this.state.trackingId &&
      this.state.interval &&
      this.state.fromDate &&
      this.state.toDate
    ) {
      this.props.clearTrackingHistory();
      if (this.props.searchTrackingHistory) {
        this.props.searchTrackingHistory(
          this.state.unitChecked ? "unit" : "order",
          this.state.trackingId,
          this.state.interval,
          moment().format(
            `${moment(this.state.fromDate).format("YYYY.MM.DD")} ${
              this.state.fromTime
            }`
          ),
          moment().format(
            `${moment(this.state.toDate).format("YYYY.MM.DD")} ${
              this.state.toTime
            }`
          )
        );
      }
    }
  };

  formatDate = date => {
    return moment(date).format("DD.MM.YYYY");
  };

  validateInputTime = value => {
    if (value) {
      let timeExploded = value.replace(/_/g, "").split(":");
      let hh = timeExploded[0];
      let mm = timeExploded[1];

      if (hh.length === 2) {
        let tmpHH = parseInt(hh);
        let newHH = 0;
        if (tmpHH >= 24) {
          newHH = tmpHH - 24;
          if (newHH <= 9) {
            hh = `0${newHH}`;
          } else {
            hh = `${newHH}`;
          }
        }
      }

      if (mm.length === 2) {
        let tmpMM = parseInt(mm);
        let newMM = 0;
        if (tmpMM > 59) {
          newMM = tmpMM - 60;
          if (newMM <= 9) {
            mm = `0${newMM}`;
          } else {
            mm = `${newMM}`;
          }
        }
      }

      if (parseInt(hh) < 0 || parseInt(hh) > 23) {
        hh = "00";
      }
      if (parseInt(mm) < 0 || parseInt(mm) > 60) {
        hh = "00";
      }

      const time = `${hh}:${mm}`;
      return time;
    }
  };
}
