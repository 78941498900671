import { API } from "utils/api";
import * as helpers from "utils/functions";
import { DropdownMenuItemType } from "office-ui-fabric-react/lib/Dropdown";
import _ from "lodash";

export const POI_LOAD_USERPOI = (userPois, pois) => {
  return {
    type: "POI_LOAD_USERPOI",
    userPois: userPois,
    pois: pois
  };
};

export const POI_UPDATE_SELECTED_POI = array => {
  return {
    type: "POI_UPDATE_SELECTED_POI",
    array: array
  };
};

export const updateSelectedPOI = (dispatch, array) => {
  dispatch(POI_UPDATE_SELECTED_POI(array));
};

export const loadUserPOIs = (dispatch, strings, callback) => {
  // Load Groups
  let groupList = [];
  let lastEntryWasPublic = undefined;
  return fetch(API + "/api/v1.0/User/Get/POIs", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "bearer " + localStorage.getItem("access_token")
    }
  })
    .then(response => {
      if (response.ok) {
        response.json().then(result => {
          _.forEach(_.sortBy(result, ["companyPublic"]), function(object) {
            if (object.companyPublic !== lastEntryWasPublic) {
              switch (object.companyPublic) {
                case true:
                  groupList.push({
                    key: -1,
                    text: strings.companyPublicPOIs,
                    itemType: DropdownMenuItemType.Header
                  });
                  break;
                case false:
                  groupList.push({
                    key: -2,
                    text: strings.myPOIs,
                    itemType: DropdownMenuItemType.Header
                  });
                  break;
                default:
                  break;
              }
            }
            groupList.push({ key: object.poiPk, text: object.poiName });
            lastEntryWasPublic = object.companyPublic;
          });
          dispatch(POI_LOAD_USERPOI(groupList, result));
          if (callback !== undefined) {
            callback();
          }
        });
      } else {
        response.json().then(result => {
          console.log("Failed");
        });
      }
    })
    .catch(error => {});
};

// ------------------------ PLACES -----------------------------

export const POI_LOAD_PLACES = (userPlaces, places) => {
  return {
    type: "POI_LOAD_PLACES",
    userPlaces: userPlaces,
    places: places
  };
};

export const POI_UPDATE_SELECTED_PLACE = array => {
  return {
    type: "POI_UPDATE_SELECTED_PLACE",
    array: array
  };
};

export const updateSelectedPlace = (dispatch, array) => {
  dispatch(POI_UPDATE_SELECTED_PLACE(array));
};

export const loadUserPlaces = (dispatch, strings) => {
  // Load Groups
  let groupList = [];
  let objects = [];
  let lastEntryType = undefined;
  return fetch(API + "/api/v1.0/Poi/Get", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "bearer " + localStorage.getItem("access_token")
    }
  })
    .then(response => {
      if (response.ok) {
        response.json().then(result => {
          // Bygger opp listen til å passe med DropDown kravene.
          _.forEach(_.sortBy(result, ["type"]), function(object) {
            if (object.type !== lastEntryType) {
              let headerText = "";
              switch (object.type) {
                case "BearbetningsPlatsItem":
                  headerText = helpers.Cap(strings.processingPlaces);
                  break;
                default:
                  headerText = helpers.Cap(strings.materialPlaces);
                  break;
              }
              groupList.push({
                key: headerText,
                text: headerText,
                itemType: DropdownMenuItemType.Header
              });
            }
            object.key = object.type[0] + object.name;
            objects.push(object);
            groupList.push({
              key: object.type[0] + object.name,
              text: object.name
            });
            lastEntryType = object.type;
          });
          dispatch(POI_LOAD_PLACES(groupList, objects));
        });
      } else {
        response.json().then(result => {
          console.log("Failed");
        });
      }
    })
    .catch(error => {});
};

// ------------------------ PLACES -----------------------------
