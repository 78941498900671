import React, { Component } from "react";
import PropTypes from "prop-types";
import "./Card.css";

export default class Card extends Component {
  static propTypes = {
    header: PropTypes.string,
    description: PropTypes.string,
    childeren: PropTypes.object,
    innerHeight: PropTypes.number,
    marginTop: PropTypes.number
  };

  constructor(props) {
    super(props);

    this.headerHeight = this.props.header ? 25 : 0;
    this.descriptionHeight = this.props.description ? 20 : 0;
    this.overheadHeight =
      this.headerHeight > 0 || this.descriptionHeight > 0 ? 20 : 0;
    this.marginTop = this.props.marginTop ? this.props.marginTop : 0;
    this.innerStyle = {
      width: "inherit",
      height: this.props.innerHeight
        ? this.props.innerHeight -
          this.overheadHeight -
          this.headerHeight -
          this.descriptionHeight -
          15
        : "100%"
    };
  }

  render() {
    const { header, description, children } = this.props;
    return (
      <div
        onContextMenu={e => {
          e.preventDefault();
        }}
        style={{
          width: "inherit",
          height: "inherit",
          padding: "1px",
          marginTop: this.marginTop,
          backgroundColor: this.props.transparent ? "" : "#FFFFFF"
        }}
      >
        <div className="card" style={{ width: "inherit", height: "inherit" }}>
          {header || description ? (
            <div className="content">
              {header ? (
                <div className="header ms-font-xl ">{header}</div>
              ) : null}
              {description ? (
                <div className="meta ms-font-m">{description}</div>
              ) : null}
            </div>
          ) : null}

          <div
            className="content"
            style={{ width: "inherit", height: "inherit" }}
          >
            <div
              className="description ms-font-m"
              style={this.innerStyle}
              onContextMenu={e => {
                e.preventDefault();
              }}
            >
              {children}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
