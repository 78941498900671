/* global google */
import React, { Component } from "react";
import { Marker, Polyline, OverlayView } from "react-google-maps";
import moment from "moment";

export default class Tracking extends Component {
  constructor(props) {
    super(props);

    this.state = {
      path: [],
      markers: [],
      polyline: undefined,
      overlay: undefined
    };
  }

  componentDidMount = () => {
    this.createObjects();
  };

  render() {
    return (
      <div>
        {this.state.markers}
        {this.state.polyline}
        {this.state.overlay}
      </div>
    );
  }

  createMarker = (item, position, icon, key) => {
    let overlay = (
      <OverlayView
        position={position}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        getPixelPositionOffset={(width, height) => ({ x: 15, y: -35 })}
      >
        <div className="poiLabelWhite">
          <table>
            <tbody>
              <tr>
                <td className="ms-fontSize-m">Senaste position</td>
                <td className="ms-fontSize-s">
                  {moment(item.timeStamp).format("LLL")}
                </td>
              </tr>
              <tr>
                <td className="ms-fontSize-m">Hastighet</td>
                <td>{item.speed}</td>
              </tr>
              <tr>
                <td className="ms-fontSize-m">Riktning</td>
                <td>{item.bearing}</td>
              </tr>
              {item.currentActiveOrderNumbers}
              <tr>
                <td className="ms-fontSize-m">Riktning</td>
                <td>{item.bearing}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </OverlayView>
    );

    return (
      <div key={key}>
        <Marker
          title="Marker"
          icon={icon}
          position={position}
          onMouseOver={() => this.showOverlay(overlay)}
          onMouseOut={() => this.showOverlay(null)}
        />
      </div>
    );
  };

  showOverlay = overlay => {
    this.setState({ overlay });
  };

  createObjects = () => {
    const { trackingArray, map } = this.props;
    const { markers, path } = this.state;
    let bounds = new google.maps.LatLngBounds();
    let polyline = null;

    var startIcon = {
      url: "https://maps.google.com/mapfiles/ms/icons/green-dot.png",
      scaledSize: new google.maps.Size(45, 45)
    };

    var endIcon = {
      url: "https://maps.google.com/mapfiles/ms/icons/red-dot.png",
      scaledSize: new google.maps.Size(45, 45)
    };
    var ongoingIcon = {
      url: "https://maps.google.com/mapfiles/ms/icons/yellow-dot.png"
    };

    trackingArray.forEach((item, i) => {
      let coordinateLatLng = new google.maps.LatLng(
        item.latitude,
        item.longitude
      );
      bounds.extend(coordinateLatLng);
      let icon = null;

      if (i === 0) {
        icon = startIcon;
      } else if (i === trackingArray.length - 1) {
        icon = endIcon;
      } else {
        icon = ongoingIcon;
      }

      let marker = this.createMarker(item, coordinateLatLng, icon, i);
      markers.push(marker);
      path.push(coordinateLatLng);
    });

    map.fitBounds(bounds);
    var iconsetngs = { path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW };
    polyline = (
      <Polyline
        path={path}
        options={{
          strokeColor: "black",
          strokeOpacity: 1.0,
          strokeWeight: 3,
          icons: [
            {
              repeat: "100px",
              icon: iconsetngs,
              offset: "100%"
            }
          ]
        }}
      />
    );
    this.setState({ markers, path, polyline });
  };
}
