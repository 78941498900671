// import { API } from "utils/api";
// import * as helpers from "utils/functions";
// import _ from "lodash";

export const USERADM_SHOWDIALOG = value => {
  return {
    type: "USERADM_SHOWDIALOG",
    visible: value
  };
};
