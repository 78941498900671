let HTTP = "http";
let APIHOSTNAME = window.location.hostname + ":6767";

if (process && process.env.NODE_ENV === "development") {
  HTTP = "https";
  APIHOSTNAME = `api.test.tdxweb.se`;
}

if (window.location.hostname.indexOf("tdxweb.se") > 0) {
  HTTP = "https";
  APIHOSTNAME = `api.${window.location.hostname}`;
}

export let API = `${HTTP}://${APIHOSTNAME}`;
