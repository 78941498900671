import React, { Component } from "react";
import * as helpers from "utils/functions";
import moment from "moment";
import Card from "components/Card/Card";
// import { Dropdown, IDropdownOption } from "office-ui-fabric-react/lib/Dropdown";
import { DatePicker } from "office-ui-fabric-react/lib/DatePicker";
import "./Content.css";

export default class History extends Component {
  render() {
    const { strings } = this.props;
    return (
      <div>
        <Card header={helpers.Cap(strings.history)}>
          <div className="row">
            <div className="col">
              <DatePicker
                label={helpers.Cap(strings.from)}
                value={new Date(this.props.historyFrom)}
                formatDate={date => moment(date).format("DD.MM.YYYY")}
                placeholder={strings.selectDate}
                onSelectDate={date => this.props.setHistoryDateFrom(date)}
              />
            </div>
            <div className="col">
              <DatePicker
                label={helpers.Cap(strings.to)}
                value={new Date(this.props.historyTo)}
                formatDate={date => moment(date).format("DD.MM.YYYY")}
                placeholder={strings.selectDate}
                onSelectDate={date => this.props.setHistoryDateTo(date)}
              />
            </div>
          </div>
        </Card>
      </div>
    );
  }
}
