export default {
  activeUnit: "aktiv enhet",
  address: "addresse",
  and: " og ",
  centerMap: "sentrer kart",
  companies: "selskaper",
  company: "selskap",
  customer: "kunde",
  date: "dato",
  distanceInKm: "Distance i Km",
  driver: "sjåfør",
  emailLabel: "Epost",
  endTime: "Slutt tidspunkt",
  followUnit: "Følg enhet",
  from: "fra",
  groups: "grupper",
  hide: "skjul",
  history: "historikk",
  locate: "lokaliser",
  locateInMap: "Lokaliser i kart",
  login: "Logg på",
  loginExpired:
    "Din sessjon har snart utløpt, vennligst logg på med ditt brukernavn og passord for ikke å miste din sessjon.",
  loginHeaderText: "Pålogg",
  loginInProgress: "Logger på",
  logout: "Logg av",
  map: "kart",
  mapsettings: "instillinger for kart",
  noUnitsApplicable: "Ingen enheter tilgjengelig",
  notresponsiveheader: "Notice",
  notresponsivetext: "This page is not created for mobile devices.",
  office: "kontor",
  offices: "kontorer",
  order: "ordre",
  orderInformation: "Ordreinformasjon",
  orderStatusTimeStamp: "Siste statusendring",
  passwordLabel: "Passord",
  poi: "poi",
  posAge: "Siste posisjon",
  posInformation: "Posisjonsinformasjon",
  search: "søk",
  select: "velg",
  selectAll: "Valg alle",
  selectDate: "Valg en dato",
  selectCompany: "Velg selskap",
  selectLanguage: "Velg språk",
  selectNone: "Valg ingen",
  selectOfficeOrGroup: "Velg kontor eller grupper",
  selectUnits: "Velg enhet",
  selection: "valg",
  setSelectedUnit: "Sett som valgt enhet",
  show: "vis",
  showAllUnitsInMap: "Vis valgte enheter i kartutsnitt",
  showInMap: "Vis i kart",
  speed: "hastighet",
  startTime: "Start tidspunkt",
  status: "status",
  tdx: "TDX",
  to: "til",
  tracking: "sporing",
  unit: "enhet",
  unitCategory: "Enhetskategori",
  unitHistory: "Enhetshistorikk",
  unitInformation: "Enhetsinformasjon",
  units: "enheter",
  userSettingsHeaderText: "Brukerinstillinger",
  usernameLabel: "Brukernavn",
  version: "Versjon",
  zoomToUnit: "Zoom til enhet"
};
