import React, { Component } from "react";
import { Modal } from "office-ui-fabric-react/lib/Modal";
import { PrimaryButton } from "office-ui-fabric-react/lib/Button";
import "./HelpScreen.css";

export default class HelpScreen extends Component {
  render() {
    const { strings } = this.props;
    return (
      <Modal
        containerClassName="NewGroupFormModalContainer ms-scaleUpIn100"
        isOpen={this.props.isOpen}
        onDismiss={this.props.onDismiss}
        isBlocking={false}
      >
        <div className="NewGroupFormContainer ">
          <div className="NewGroupFormHeader">
            <font className="ms-font-xxl">{strings.saveGroup}</font>
          </div>
          <div className="NewGroupFormContent container">
            <div className="row">
              <div className="col">{strings.help}</div>
            </div>
          </div>
          <div className="NewGroupFormFooter">
            <PrimaryButton
              className="NewGroupFormButton"
              description=""
              onClick={this.onDismiss}
              text={strings.abort}
            />
          </div>
        </div>
      </Modal>
    );
  }

  onDismiss = () => {
    this.setState(this.initialState);
    this.props.onDismiss();
  };
}
