import React, { Component } from "react";
import * as helpers from "utils/functions";
import { Dropdown } from "office-ui-fabric-react/lib/Dropdown";
// import { PrimaryButton } from "office-ui-fabric-react/lib/Button";
import Card from "components/Card/Card";
import "./Content.css";

export default class TDX extends Component {
  render() {
    const { strings } = this.props;
    return (
      <div>
        <Card
          header={helpers.Cap(strings.groups) + strings.and + strings.offices}
        >
          <div className="row">
            <div className="col">
              <Dropdown
                placeHolder={strings.selectOfficeOrGroup}
                id="groupsAndOffices"
                ariaLabel="Custom dropdown example"
                onChange={(e, option) =>
                  this.props.updateSelectedGroups(option)
                }
                selectedKeys={this.props.selectedGroups}
                multiSelect={true}
                multiSelectDelimiter=","
                options={this.props.groupItems}
              />
            </div>
            {/* <div className="col">
              <PrimaryButton text="Clear" onClick={() => this.props.clearSelectedGroups()} />
            </div> */}
          </div>
        </Card>
      </div>
    );
  }
}
