import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import en from "./en";
import nb from "./nb";
import sv from "./sv";
const languages = { en, nb, sv };

export default function translate(key) {
  return Component => {
    const stateToProps = state => ({
      currentLanguage: state.application.currentLanguage
    });

    class TranslationComponent extends React.Component {
      render() {
        let strings;
        if (key === undefined) {
          strings = languages[this.props.currentLanguage];
        } else {
          strings = languages[this.props.currentLanguage][key];
        }

        const merged = {
          ...this.props.strings,
          ...strings
        };
        if (strings) {
          return (
            <Component
              {...this.props}
              strings={merged}
              currentLanguage={this.props.currentLanguage}
            />
          );
        }
        return (
          <Component
            {...this.props}
            currentLanguage={this.props.currentLanguage}
          />
        );
      }
    }

    TranslationComponent.propTypes = {
      strings: PropTypes.object,
      currentLanguage: PropTypes.string
    };

    return connect(stateToProps)(TranslationComponent);
  };
}
