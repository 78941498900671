const AuthenticationReducers = (state = {}, action) => {
  switch (action.type) {
    case "AUTHENTICATION_REQUEST":
      return { ...state, authRequested: action.authRequested };
    case "AUTHENTICATION_SUCCESS":
      return {
        ...state,
        authRequested: action.authRequested,
        isAuthenticated: action.isAuthenticated,
        preAuthRequest: false,
        message: action.message
      };
    case "AUTHENTICATION_FAILED":
      return {
        ...state,
        authRequested: action.authRequested,
        companies: [],
        isAuthenticated: false,
        preAuthRequest: false,
        message: action.message
      };
    case "AUTHENTICATION_RESET":
      return {
        ...state,
        isAuthenticated: false,
        isPreAuth: false,
        companies: []
      };
    case "AUTHENTICATION_VALIDATE":
      return {
        ...state,
        isAuthenticated: action.isAuthenticated,
        message: action.message
      };
    case "AUTHENTICATION_USERINFO":
      return { ...state, userInfo: action.userInfo };
    case "AUTHENTICATION_PREAUTH":
      return {
        ...state,
        authRequested: false,
        companies: action.companies,
        isPreAuth: action.isPreAuth,
        preAuthRequest: false,
        message: undefined
      };

    case "AUTHENTICATION_PREAUTH_REQUEST":
      return {
        ...state,
        authRequested: false,
        preAuthRequest: action.preAuthRequest
      };
    default:
      return state;
  }
};

export default AuthenticationReducers;
