import _ from "lodash";
// Reducer
const LogReducers = (state = {}, action) => {
  switch (action.type) {
    case "LOGDATA_ADD":
      let items = state.logitems;
      items.unshift(action.logitem);
      // let newItems = items.slice(0, 200);
      return { ...state, isLoading: false, logitems: [...items], updated: new Date().getTime() };

    case "LOGDATA_SETCOUNTERS":
      return {
        ...state,
        counters: {
          debug: action.debug,
          info: action.info,
          warning: action.warning,
          error: action.error,
          fatal: action.fatal
        }
      };

    case "LOGDATA_UPDATECOUNTERS":
      items = state.logitems;
      // Opprettet ny array som er gruppert på level, og henter ut lengden pr. level
      if (items) {
        let numberOfItems = _(items).groupBy("level").value();
        let debug = numberOfItems[0] ? numberOfItems[0].length : 0;
        let info = numberOfItems[1] ? numberOfItems[1].length : 0;
        let warning = numberOfItems[2] ? numberOfItems[2].length : 0;
        let error = numberOfItems[3] ? numberOfItems[3].length : 0;
        let fatal = numberOfItems[4] ? numberOfItems[4].length : 0;
        return { ...state, counters: { debug: debug, info: info, warning: warning, error: error, fatal: fatal } };
      }
      return { ...state, counters: { debug: 0, info: 0, warning: 0, error: 0, fatal: 0 } };

    case "LOGDATA_REQUEST":
      return { ...state, isLoading: true };
    case "LOGDATA_LOAD":
      return { ...state, isLoading: false, logitems: action.logitems };
    case "LOGDATA_SORTED":
      return {
        ...state,
        isLoading: false,
        logitems: action.logitems
      };
    default:
      return state;
  }
};

// Mapping
export default LogReducers;
