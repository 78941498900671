import React, { Component } from "react";
import ReactDOM from "react-dom";
import _ from "lodash";
import * as helpers from "utils/functions";
import { Checkbox } from "office-ui-fabric-react/lib/Checkbox";
import Card from "components/Card/Card";
import UnitContextualMenu from "components/ContextualMenu/Unit/UnitContextualMenu";
import { ContextualMenuItemType } from "office-ui-fabric-react/lib/ContextualMenu";
import "./UnitList.css";

export default class UnitList extends Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.state = {
      showContextualMenu: false,
      contextualMenuTarget: undefined,
      targetPoint: { x: 0, y: 0 },
      contextualTargetUnit: 0
    };
  }

  /*
    This is not optimal - we use alot of time rendering this component,
    and having to calculate the element height on every update makes it 
    slow.
  */
  componentDidUpdate = () => {
    this.calculateMaxHeight();
  };

  componentDidMount = () => {
    this.calculateMaxHeight();
  };

  render() {
    const { strings, units, selectedUnits } = this.props;
    return (
      <div
        ref={this.containerRef}
        style={{
          maxHeight: `${this.props.mapHeight - this.state.positionTop}px`
        }}
      >
        <Card
          marginTop={5}
          header={helpers.Cap(strings.units)}
          scrollable={true}
        >
          <UnitContextualMenu
            isHidden={!this.state.showContextualMenu}
            target={this.state.targetPoint}
            onDismiss={() =>
              this.setState({
                showContextualMenu: !this.state.showContextualMenu
              })
            }
            items={[
              {
                key: "mapSection",
                itemType: ContextualMenuItemType.Section,
                sectionProps: {
                  key: "mapSectionProps",
                  topDivider: true,
                  bottomDivider: true,
                  title: helpers.Cap(strings.map),
                  items: [
                    {
                      key: "select",
                      name: helpers.Cap(strings.select),
                      disabled:
                        this.state.contextualTargetUnit &&
                        this.props.unitsRefs[this.state.contextualTargetUnit]
                          ? false
                          : true,
                      onClick: () => {
                        this.state.contextualTargetUnit &&
                          this.props.setActiveUnit(
                            this.state.contextualTargetUnit
                          );
                      }
                    },
                    {
                      key: "locateInMap",
                      name: strings.locateInMap,
                      disabled:
                        this.state.contextualTargetUnit &&
                        this.props.unitsRefs[this.state.contextualTargetUnit]
                          ? false
                          : true,
                      onClick: () =>
                        this.state.contextualTargetUnit &&
                        this.props.unitsRefs[this.state.contextualTargetUnit] &&
                        this.props.unitsRefs[
                          this.state.contextualTargetUnit
                        ].locateUnit()
                    },
                    {
                      key: "zoomToUnit",
                      name: strings.zoomToUnit,
                      disabled:
                        this.state.contextualTargetUnit &&
                        this.props.unitsRefs[this.state.contextualTargetUnit]
                          ? false
                          : true,
                      onClick: () => {
                        this.state.contextualTargetUnit &&
                          this.props.setMapCenter(
                            this.state.contextualTargetUnit,
                            19
                          );
                      }
                    },

                    {
                      key: "followUnit",
                      name: strings.followUnit,
                      canCheck: true,
                      checked: this.props.trackUnit,
                      disabled:
                        this.state.contextualTargetUnit &&
                        this.props.unitsRefs[this.state.contextualTargetUnit]
                          ? false
                          : true,
                      onClick: () => {
                        this.props.toggleTrackUnit();
                      }
                    },
                    {
                      key: "showAllUnitsInMap",
                      name: helpers.Cap(strings.showAllUnitsInMap),
                      disabled:
                        this.state.contextualTargetUnit &&
                        this.props.unitsRefs[this.state.contextualTargetUnit]
                          ? false
                          : true,
                      onClick: () => {
                        this.state.contextualTargetUnit &&
                          this.props.setMapBounds();
                      }
                    }
                  ]
                }
              },

              {
                key: "selectionSection",
                itemType: ContextualMenuItemType.Section,
                sectionProps: {
                  key: "selectionSectionProps",
                  topDivider: true,
                  bottomDivider: true,
                  title: helpers.Cap(strings.units),
                  items: [
                    {
                      key: "selectAll",
                      name: helpers.Cap(strings.selectAll),
                      onClick: () => {
                        this.state.contextualTargetUnit &&
                          this.props.setSelectedUnits(units);
                      }
                    },
                    {
                      key: "selectNone",
                      name: helpers.Cap(strings.selectNone),
                      onClick: () => {
                        this.state.contextualTargetUnit &&
                          this.props.setSelectedUnits([]);
                      }
                    }
                  ]
                }
              },

              {
                key: "selectionSection",
                itemType: ContextualMenuItemType.Section,
                sectionProps: {
                  key: "selectionSectionProps",
                  topDivider: true,
                  bottomDivider: true,
                  title: helpers.Cap(strings.groups),
                  items: [
                    {
                      key: "addToGroup",
                      name: helpers.Cap(strings.newGroup),
                      onClick: () => {
                        this.props.newGroup && this.props.newGroup(units);
                      }
                    }
                  ]
                }
              }

              // {
              //   key: "properties",
              //   name: "Properties"
              // },
              // {
              //   key: "disabled",
              //   name: "Disabled item",
              //   disabled: true
              // }
            ]}
          />

          <div
            style={{
              // height: `${this.props.mapHeight - this.state.positionTop}px`,
              maxHeight: `${this.props.mapHeight - this.state.positionTop}px`,
              overflowY: "auto",
              overflowX: "hidden"
            }}
          >
            {units.map((unit, index) => {
              let isChecked =
                _.indexOf(selectedUnits, unit.radioNumber) >= 0 ? true : false;
              let isActiveUnit =
                this.props.activeUnit &&
                this.props.activeUnit === unit.radioNumber
                  ? true
                  : false;
              let activeClass = isActiveUnit === true ? " activeItem " : "";
              return (
                <div
                  key={index}
                  className={
                    "row unit_" + index + " hand mousehover " + activeClass
                  }
                  style={{ marginTop: 5, userSelect: "none" }}
                  onClick={() =>
                    this.onClickSelectedItem(isChecked, unit.radioNumber)
                  }
                  onContextMenu={e =>
                    this.onContextualMenu(e, unit.radioNumber)
                  }
                >
                  <div className="col-1">
                    <Checkbox
                      checked={isChecked}
                      onChange={(e, value) =>
                        this.onClickSelectItem(isChecked, unit.radioNumber)
                      }
                    />
                  </div>
                  <div className="col-2">{unit.radioNumber}</div>
                  <div className="unitRow col">{unit.enhKatText}</div>
                </div>
              );
            })}
          </div>
        </Card>
      </div>
    );
  }

  onContextualMenu = (e, radioNumber) => {
    e.preventDefault();
    this.setState({
      showContextualMenu: !this.state.showContextualMenu,
      contextualMenuTarget: e.target,
      contextualTargetUnit: radioNumber,
      targetPoint: { x: e.pageX, y: e.pageY }
    });
  };

  onClickSelectedItem = (isChecked, unit) => {
    this.props.clearUnitLocation();
    if (isChecked) {
      this.props.setActiveUnit(unit);
    } else {
      this.props.updateSelectedUnits(unit);
      this.props.setActiveUnit(unit);
    }
  };

  onClickSelectItem = (isChecked, unit) => {
    this.props.clearUnitLocation();
    this.props.clearAddressSearchObject();
    this.props.updateSelectedUnits(unit);
  };

  calculateMaxHeight() {
    let position = ReactDOM.findDOMNode(
      this.containerRef.current
    ).getBoundingClientRect();
    if (position.top !== this.state.positionTop) {
      this.setState({ positionTop: position.top });
    }
  }
}
