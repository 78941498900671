import { API } from "utils/api";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import translate from "i18n/Translate";
import * as helpers from "utils/functions";
import { Modal } from "office-ui-fabric-react/lib/Modal";
import {
  DefaultButton,
  PrimaryButton,
  TextField,
  Checkbox
} from "office-ui-fabric-react";
import "./UserEditForm.css";

export default withRouter(
  translate()(
    class UserEditForm extends Component {
      constructor(props) {
        super(props);
        this.state = {
          visible: true,
          username: "",
          displayEmployeeNumber: false,
          roles: [],
          changePassword: false,
          user: {
            userName: this.props.user.userName,
            name: this.props.user.name,
            roleName: this.props.user.roleName,
            phoneNo: this.props.user.phoneNo,
            email: this.props.user.email,
            akarNo: this.props.user.akarNo,
            customerNo: this.props.user.customerNo,
            employeeNo: this.props.user.employeeNo
          },
          errors: {
            akarNo: "",
            customerNo: "",
            general: "",
            username: "",
            company: "",
            email: "",
            name: "",
            phone: "",
            role: "",
            password: "",
            passwordRetype: ""
          }
        };
      }

      render() {
        const { strings } = this.props;
        let user = this.state.user;
        return (
          <Modal
            containerClassName="UserEditFormModalContainer ms-scaleUpIn100"
            isOpen={this.state.visible}
            onDismiss={this.onDismiss}
            isBlocking={true}
            scrollableContentClassName="ModalContainerNoScroll"
          >
            <div className="UserEditFormContainer ">
              <div className="UserEditFormHeader">
                <font className="ms-font-xxl">{strings.newUser}</font>
              </div>
              <div className="UserEditFormContent">
                <div className="row">
                  <div className="col-12">
                    <TextField
                      label={helpers.Cap(strings.companies)}
                      value={this.props.user.companyName}
                      disabled={true}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <TextField
                      label={helpers.Cap(strings.role)}
                      value={this.props.user.roleName}
                      disabled={true}
                      errorMessage={this.state.errors.role}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <TextField
                      label={strings.usernameLabel}
                      type="text"
                      disabled={true}
                      value={this.props.user.userName}
                    />
                  </div>
                </div>

                {this.createRoleCustomField(this.props.user.rolePk)}

                <div className="row">
                  <div className="col-12">
                    <TextField
                      label={strings.emailLabel}
                      type="text"
                      value={this.state.user.email}
                      onChanged={value => {
                        user.email = value;
                        this.setState({ user });
                      }}
                      errorMessage={this.state.errors.email}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-7">
                    <TextField
                      label={strings.name}
                      type="text"
                      value={this.state.user.name}
                      onChanged={value => {
                        user.name = value;
                        this.setState({ user });
                      }}
                      errorMessage={this.state.errors.name}
                    />
                  </div>
                  <div className="col-5">
                    <TextField
                      label={strings.phoneLabel}
                      type="text"
                      value={this.state.user.phoneNo}
                      onChanged={value => {
                        user.phoneNo = value;
                        this.setState({ user });
                      }}
                      errorMessage={this.state.errors.phone}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <Checkbox
                      label={strings.changePassword}
                      onChange={this.onCheckboxChange}
                      styles={{
                        root: {
                          marginTop: "10px"
                        }
                      }}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-6">
                    <TextField
                      label={strings.passwordLabel}
                      type="password"
                      autoComplete="new-password"
                      disabled={!this.state.changePassword}
                      onChanged={value => this.setState({ password: value })}
                      errorMessage={this.state.errors.password}
                      value={
                        this.state.password !== undefined
                          ? this.state.password
                          : ""
                      }
                    />
                  </div>

                  <div className="col-6">
                    <TextField
                      label={strings.passwordRetypeLabel}
                      type="password"
                      autoComplete="new-password"
                      disabled={!this.state.changePassword}
                      onChanged={value =>
                        this.setState({ password_retype: value })
                      }
                      errorMessage={this.state.errors.passwordRetype}
                      value={
                        this.state.password_retype !== undefined
                          ? this.state.password_retype
                          : ""
                      }
                    />
                  </div>
                </div>

                {this.state.errors.general && (
                  <div className="row">
                    <div
                      className="col-12 ms-fontColor-redDark"
                      style={{ marginTop: "15px" }}
                    >
                      {this.state.errors.general}
                    </div>
                  </div>
                )}
              </div>

              <div className="UserEditFormFooter">
                <DefaultButton
                  className="UserEditFormButton"
                  onClick={this.onDismiss}
                  text={strings.abort}
                />
                <PrimaryButton
                  className="UserEditFormButton"
                  text={strings.save}
                  onClick={this.saveUser}
                />
              </div>
            </div>
          </Modal>
        );
      }

      createRoleCustomField = rolePk => {
        const { strings } = this.props;
        let user = this.state.user;

        switch (parseInt(rolePk, 10)) {
          case 3: // Bruker
            return (
              <div className="row">
                <div className="col-12">
                  <TextField
                    label={helpers.Cap(strings.employeeNumber)}
                    value={this.state.user.employeeNo}
                    type="text"
                    onChanged={value => {
                      user.employeeNo = value;
                      this.setState({ user });
                    }}
                  />
                </div>
              </div>
            );

          case 4: // Fører
          case 5: // Fører
          case 7: // Fører
            return (
              <div className="row">
                <div className="col-12">
                  <TextField
                    label={helpers.Cap(strings.driverNumber)}
                    type="text"
                    value={this.state.user.akarNo}
                    onChanged={value => {
                      user.akarNo = value;
                      this.setState({ user });
                    }}
                    errorMessage={this.state.errors.akarNo}
                  />
                </div>
              </div>
            );

          case 6: // Kunde
            return (
              <div className="row">
                <div className="col-12">
                  <TextField
                    label={helpers.Cap(strings.customerNumber)}
                    type="text"
                    value={this.state.user.customerNo}
                    onChanged={value => {
                      user.customerNumber = value;
                      this.setState({ user });
                    }}
                    errorMessage={this.state.errors.customerNo}
                  />
                </div>
              </div>
            );

          default:
            return null;
        }
      };

      saveUser = () => {
        const regexIsNotNumber = /[^0-9]/g;
        const errors = [];
        let isValid = true;

        if (!this.state.user.email) {
          errors.email = this.props.strings.errorMessageMissingEmail;
          isValid = false;
        }

        if (!this.state.user.name) {
          errors.name = this.props.strings.errorMessageMissingName;
          isValid = false;
        }

        if (!this.state.user.phoneNo) {
          errors.phone = this.props.strings.errorMessageMissingPhoneNo;
          isValid = false;
        }

        if (this.state.user.akarNo) {
          if (regexIsNotNumber.test(this.state.akarNo)) {
            errors.akarNo = this.props.strings.errorMessageMissingAkarNo;
            isValid = false;
          }
        }

        if (this.state.user.customerNo) {
          if (regexIsNotNumber.test(this.state.customerNo)) {
            errors.customerNo = this.props.strings.errorMessageMissingCustomerNo;
            isValid = false;
          }
        }

        if (this.state.password !== this.state.password_retype) {
          errors.passwordRetype = this.props.strings.errorMessagePasswordMismatch;
          isValid = false;
        }

        if (!isValid) {
          this.setState({ errors });
          return;
        }

        const editUser = {
          username: this.state.user.userName,
          email: this.state.user.email,
          name: this.state.user.name,
          phoneNo: this.state.user.phoneNo,
          akarNo: this.state.user.akarNo,
          customerNo: this.state.user.customerNo,
          employeeNo: this.state.user.employeeNo,
          userInfoPk: this.props.user.userInfoPk,
          password: this.state.password,
          roleList: [
            {
              rolePk: this.props.user.rolePk
            }
          ],
          companyList: [
            {
              CompanyPk: this.props.user.companyPk
            }
          ]
        };

        return fetch(API + "/api/v1.0/Administrator/Edit", {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "bearer " + localStorage.getItem("access_token")
          },
          body: JSON.stringify(editUser)
        })
          .then(response => {
            if (response.ok) {
              // Request success
              this.onDismiss();
              this.props.onSuccessCallback();
            } else {
              // Request failed
              response.json().then(result => {
                helpers.notifyGenericError(this.props.strings, result.Message);
                this.setState({ error: result.Message });
              });
            }
          })
          .catch(error => {
            this.setState({ error: error });
          });
      };

      onDismiss = () => {
        this.setState({ visible: false });
        this.props.toggleUserAdministration(true);
      };

      onCheckboxChange = () => {
        let errors = Object.assign({}, this.state.errors);
        errors.passwordRetype = undefined;

        this.setState({
          changePassword: !this.state.changePassword,
          password: undefined,
          password_retype: undefined,
          errors
        });
      };
    }
  )
);
