import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import translate from "i18n/Translate";
import { connect } from "react-redux";
import { mapDispatchToProps } from "actions/mapDispatchToProps";
// import * as APPVAR from "utils/constants";
import * as helpers from "utils/functions";
import { API } from "utils/api";
import moment from "moment";

import { Modal } from "office-ui-fabric-react/lib/Modal";
import {
  PrimaryButton,
  DefaultButton
} from "office-ui-fabric-react/lib/Button";
import {
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode,
  Selection
} from "office-ui-fabric-react/lib/DetailsList";
import { CommandBar } from "office-ui-fabric-react/lib/CommandBar";
import UserRegistrationForm from "./UserRegistrationForm/UserRegistrationForm";
import UserEditForm from "./UserEditForm/UserEditForm";

import "./UserAdministration.css";
import UserNewRole from "./UserNewRole/UserNewRole";

const mapStateToProps = state => {
  return state;
};

class UserAdministration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showNewUserForm: false,
      userList: []
    };

    this._selection = new Selection({
      // onSelectionChanged: () => {
      //   if (this._selection.getSelectedCount() >= 1) {
      //     this.setState({ selectedUser: this._selection.getSelection()[0] });
      //   } else {
      //     this.setState({ selectedUser: undefined });
      //   }
      // }
    });
  }

  componentDidMount = () => {
    if (this.props.authentication.userInfo.userType > 1) {
      this.loadUsers();
    }
  };

  render() {
    const { userAdmin } = this.props.application;
    const { strings } = this.props;
    const { userList } = this.state;
    if (this.state.showNewUserForm && userAdmin.isActive === false) {
      return <UserRegistrationForm onSuccessCallback={this.loadUsers} />;
    }

    if (this.state.selectedUser && this.state.showDeleteDialog) {
      return (
        <Modal
          isOpen={this.state.showDeleteDialog}
          onDismiss={this.onDeleteDialogDismissed}
          isBlocking={true}
          containerClassName="user-administration-deletecontainer ms-scaleUpIn100"
        >
          <div className="user-administration-container ">
            <div className="user-administration-header">
              <font className="ms-font-xxl">{strings.deleteUser}?</font>
            </div>
            <div style={{ height: "50px" }}>
              {strings.deleteUserMessage}{" "}
              <b>{this.state.selectedUser.userName}</b>
            </div>
            <div className="user-administration-footer">
              <PrimaryButton onClick={this.deleteUser} text={strings.yes} />

              <DefaultButton
                onClick={this.onDeleteDialogDismissed}
                text={strings.abort}
              />
            </div>
          </div>
        </Modal>
      );
    }

    if (
      this.state.selectedUser &&
      this.state.showEditUserForm &&
      userAdmin.isActive === false
    ) {
      return (
        <UserEditForm
          user={this.state.selectedUser}
          toggleUserAdministration={this.props.toggleUserAdministration}
          onSuccessCallback={this.loadUsers}
        />
      );
    }

    if (
      this.state.selectedUser &&
      this.state.showAddRoleForm &&
      userAdmin.isActive === false
    ) {
      return (
        <UserNewRole
          user={this.state.selectedUser}
          toggleUserAdministration={this.props.toggleUserAdministration}
          onSuccessCallback={this.loadUsers}
        />
      );
    }

    return (
      <div>
        <Modal
          containerClassName="user-administration-modalcontainer ms-scaleUpIn100"
          isOpen={userAdmin.isActive}
          onDismiss={this.onDismiss}
          isBlocking={false}
        >
          <div className="user-administration-container ">
            <div className="user-administration-header">
              <font className="ms-font-xxl">{strings.useradministration}</font>
            </div>
            <div>
              <CommandBar items={this.getCommandItems()} />
              <div
                className="user-administration-content"
                style={{ overflowY: "auto", height: "70vh" }}
              >
                <DetailsList
                  className="user-administration-list"
                  items={userList}
                  isSearchBoxVisible={false}
                  searchPlaceholderText="Search..."
                  selectionMode={SelectionMode.single}
                  // selection={this._selection}
                  onActiveItemChanged={this.onUserSelected}
                  columns={this.renderColumns()}
                  isHeaderVisible={true}
                  layoutMode={DetailsListLayoutMode.justified}
                  compact={true}
                />
              </div>
            </div>
            <div className="user-administration-footer">
              <PrimaryButton
                className="user-administration-button"
                onClick={this.onDismiss}
                text={strings.close}
              />
            </div>
          </div>
        </Modal>
      </div>
    );
  }

  onAddNewuser = () => {
    this.props.toggleUserAdministration(false);
    this.setState({
      showNewUserForm: true,
      showEditUserForm: false,
      showAddRoleForm: false
    });
  };

  onAddRoleClick = () => {
    this.props.toggleUserAdministration(false);
    this.setState({
      showNewUserForm: false,
      showEditUserForm: false,
      showAddRoleForm: true
    });
  };

  onEditClicked = () => {
    this.props.toggleUserAdministration(false);
    this.setState({
      showEditUserForm: true,
      showNewUserForm: false,
      showAddRoleForm: false
    });
  };

  onEditDialogDismissed = () => {
    this.setState({ showEditUserForm: false });
  };

  onDeleteClicked = () => {
    this.setState({ showDeleteDialog: true });
  };

  onDeleteDialogDismissed = () => {
    this.setState({ showDeleteDialog: false });
  };

  onUserSelected = item => {
    this.setState({ selectedUser: item });
  };

  deleteUser = () => {
    const { selectedUser } = this.state;
    if (selectedUser) {
      return fetch(
        API +
          `/api/v1.0/Administrator/Delete/${selectedUser.companyUserPk}/${
            selectedUser.rolePk
          }`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: "bearer " + localStorage.getItem("access_token")
          }
        }
      )
        .then(response => {
          if (response.ok) {
            // Request success
            this.loadUsers();
            this.setState({ showDeleteDialog: false });
          } else if (response.error) {
            console.error(`deleteUser: (${response.status}) ${response.error}`);
          } else {
            // Request failed
            response.json().then(result => {
              console.error(
                `deleteUser: (${response.status}) ${result.Message}`
              );
              this.setState({ error: result.Message });
            });
          }
        })
        .catch(error => {
          console.error(`deleteUser: ${error}`);
          this.setState({ error: error });
        });
    }
  };

  onDismiss = () => {
    this.props.toggleUserAdministration(false);
    this.setState({
      showNewUserForm: false,
      showEditUserForm: false,
      selectedUser: undefined
    });
  };

  renderColumns = () => {
    const { strings } = this.props;
    /*
      {
        "key": 67,
        "name": "Anders Backhed",
        "userName": "andersbackhed@kavlingeakeri.se",
        "companyName": "Akka Frakt",
        "roleName": "Användare",
        "lastLogin": "2017-04-02T22:25:27.99",
        "monthCount": 1,
        "totalCount": 152
      },
    */
    moment.locale(this.props.currentLanguage);

    return [
      {
        key: "name",
        name: strings.name,
        fieldName: "name",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true
      },
      {
        key: "userName",
        name: strings.usernameLabel,
        fieldName: "userName",
        minWidth: 200,
        maxWidth: 350,
        isResizable: true
      },
      {
        key: "companyName",
        name: helpers.Cap(strings.companies),
        fieldName: "companyName",
        minWidth: 100,
        maxWidth: 300,
        isResizable: true
      },
      {
        key: "roleName",
        name: helpers.Cap(strings.role),
        fieldName: "roleName",
        minWidth: 100,
        maxWidth: 200,
        isResizable: true
      },
      {
        key: "lastLogin",
        name: helpers.Cap(strings.lastLogin),
        fieldName: "lastLogin",
        onRender: item => {
          return moment(item.lastLogin).format("L LT"); //.format("DD.MM.YYYY");
        },
        minWidth: 100,
        maxWidth: 200,
        isResizable: true
      },
      {
        key: "monthCount",
        name: helpers.Cap(strings.month),
        fieldName: "monthCount",
        minWidth: 50,
        maxWidth: 50,
        isResizable: false
      },
      {
        key: "totalCount",
        name: helpers.Cap(strings.total),
        fieldName: "totalCount",
        minWidth: 50,
        maxWidth: 50,
        isResizable: false
      }
    ];
  };

  getCommandItems = () => {
    return [
      {
        key: "addUser",
        name: this.props.strings.new,
        iconProps: { iconName: "Add" },
        onClick: this.onAddNewuser
      },
      {
        key: "editUser",
        name: this.props.strings.edit,
        iconProps: { iconName: "Edit" },
        disabled: this.state.selectedUser ? false : true,
        onClick: this.onEditClicked
      },
      {
        key: "deleteUser",
        name: this.props.strings.delete,
        iconProps: { iconName: "Delete" },
        disabled: this.state.selectedUser ? false : true,
        onClick: this.onDeleteClicked
      },
      {
        key: "addRole",
        name: this.props.strings.newRole,
        iconProps: { iconName: "Add" },
        disabled: this.state.selectedUser ? false : true,
        onClick: this.onAddRoleClick
      },
      {
        key: "refreshList",
        name: helpers.Cap(this.props.strings.reload),
        iconProps: { iconName: "Refresh" },
        onClick: this.loadUsers
      }
    ];
  };

  validateInput = (value, inputType) => {
    return "";
  };

  loadUsers = () => {
    this.setState({ userList: [] });
    return fetch(API + `/api/v1.0/Administrator/Get/UserList`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("access_token")
      }
    })
      .then(response => {
        if (response.ok) {
          if (response.status !== 204) {
            // Request success
            response.json().then(result => {
              this.setState({ userList: result });
            });
          }
        } else {
          // Request failed
        }
      })
      .catch(error => {});
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(translate()(UserAdministration))
);
