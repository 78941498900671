import * as React from "react";
import PropTypes from "prop-types";
import { PrimaryButton } from "office-ui-fabric-react/lib/Button";
import { Spinner, SpinnerSize } from "office-ui-fabric-react/lib/Spinner";

/**
 * Loginbutton
 * 
 * @export
 * @class LoginButton
 * @extends {React.Component}
 */
export default class LoadingButton extends React.Component {
  /**
   * PropTypes
   * 
   * @property {boolean} isLoading - displaying spinner
   * @property {function} callback -  callback for onClicked
   * @static
   * @memberof ErrorLabel
   */
  static get propTypes() {
    return {
      errorMessage: PropTypes.any,
      placeholder: PropTypes.string
    };
  }
  render() {
    const { callback, isLoading, children } = this.props;

    if (isLoading) {
      return (
        <PrimaryButton onClick={callback}>
          <Spinner size={SpinnerSize.small} />
          {children}
        </PrimaryButton>
      );
    } else {
      return (
        <PrimaryButton onClick={callback}>
          {children}
        </PrimaryButton>
      );
    }
  }
}
