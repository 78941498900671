// /* global google */
import React, { Component } from "react";
import { Marker, OverlayView } from "react-google-maps";
import "./PoiMapObject.css";
// import Card from "components/Card/Card";
export default class PoiMapObject extends Component {
  constructor(props) {
    super(props);
    this.marker = null;
    this.state = {
      animation: 0
    };
  }

  render() {
    const { object, label } = this.props;
    let iconUrl = "marker";
    const position = {
      lat: parseFloat(object.latitude),
      lng: parseFloat(object.longitude)
    };
    let labelText = label ? label : object.name;

    // Verify if the object got a type, if not it's a default marker.
    let type = object.type !== undefined ? object.type : null;
    switch (type) {
      case "MaterialPlatsItem":
        iconUrl =
          "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=M|6EDBF6|FFFFFF";
        break;
      case "BearbetningsPlatsItem":
        iconUrl =
          "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=B|EB3A84|FFFFFF";
        break;
      default:
        iconUrl =
          "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=P|EB3A84|FFFFFF";
        break;
    }

    let labelCss = "poiLabelWhite";
    return (
      <Marker
        ref={marker => {
          this.marker = marker;
        }}
        onDragEnd={this.props.onDragEnd}
        title="Marker"
        icon={{
          url: iconUrl
        }}
        animation={this.state.animation}
        position={position}
        draggable={this.props.draggable}
      >
        {labelText ? (
          <OverlayView
            position={position}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            getPixelPositionOffset={(width, height) => ({ x: 15, y: -35 })}
          >
            <div
              className={"clickable " + labelCss}
              // onClick={() => this.props.onLabelClick(object)}
            >
              {/* <Card transparent={true}>{labelText}</Card> */}
              {labelText}
            </div>
          </OverlayView>
        ) : null}
      </Marker>
    );
  }
}
