/* global google */
import { API } from "utils/api";
import React, { Component } from "react";
import { TextField, Icon } from "office-ui-fabric-react";

export default class GoogleAddressSearch extends Component {
  constructor(props) {
    super(props);
    this.textFieldRef = undefined;
    this.state = {
      autocomplete: false,
      search: undefined,
      searchRef: undefined
    };
  }

  render() {
    return (
      <div>
        <TextField
          id="searchField"
          label={this.props.label}
          type="text"
          value={this.state.search}
          componentRef={input => (this.textFieldRef = input)}
          onChange={this.onSearchChange}
        />
      </div>
    );
  }

  onSearchChange = (e, value) => {
    /* 
    * På grunn av en fuckup i TextField kan man ikke bruke Ref direkte 
    * Løsningen ble å autoComplete i state ved første endring,
    */
    if (this.state.autocomplete === false) {
      let autocomplete = new google.maps.places.Autocomplete(e.target);

      autocomplete.addListener("place_changed", () => {
        this.onPlaceChange(autocomplete);
      });

      this.setState({ autocomplete: true, search: value });
    }
  };

  /*
   * Henter koordinater for lokasjonenen, og gjør en callback for å vise
   * informasjonen i kartet
   */
  onPlaceChange = autocomplete => {
    const location = autocomplete.getPlace();
    if (location !== undefined) {
      fetch(
        API +
          `/api/v1.0/Coordinates/Get/${location.geometry.location.lat()}/${location.geometry.location.lng()}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "bearer " + localStorage.getItem("access_token")
          }
        }
      )
        .then(response => {
          if (response.ok) {
            // Request success
            response.json().then(result => {
              const object = {
                latitude: location.geometry.location.lat(),
                longitude: location.geometry.location.lng(),
                name: location.name,
                formatted_address: location.formatted_address,
                details: result,
                label: this.getLabel(result, location)
              };
              this.props.callback(object);
            });
          } else {
            // Request failed
            response.text().then(result => {
              console.error(`onPlaceChange: ${result}`);
            });
          }
        })
        .catch(error => {});
    } else {
      console.warn("For some reason Location is undefined");
    }
  };

  onClear = () => {
    this.setState({ search: "" });
  };

  getLabel = (object, location) => {
    return (
      <div>
        <table border="0">
          <tbody>
            <tr>
              <td>
                <b>Address</b>
              </td>
              <td>{object.address}</td>
            </tr>
            <tr>
              <td>
                <b>WSG84</b>
              </td>
              <td>
                {object.wgs84Latitude},{object.wgs84Longitude}
              </td>
            </tr>
            <tr>
              <td>
                <b>RT90</b>
              </td>
              <td>
                {object.rt90Latitude},{object.rt90Longitude}
              </td>
            </tr>
            <tr>
              <td>
                <b>SweRef</b>
              </td>
              <td>
                {object.sweRefLatitude},{object.sweRefLongitude}
              </td>
            </tr>
          </tbody>
        </table>

        <div style={{ textAlign: "right" }}>
          <Icon
            iconName="Save"
            onClick={() => {
              this.props.onSavePOICallback(
                location.geometry.location.lat(),
                location.geometry.location.lng()
              );
            }}
          />
        </div>
      </div>
    );
  };
}
