import { API } from "utils/api";
import React, { Component } from "react";
import { TextField, Icon } from "office-ui-fabric-react";
import * as helpers from "utils/functions";

export default class CoordinatesSearch extends Component {
  constructor(props) {
    super(props);
    this.textFieldRef = undefined;
    this.state = {
      searchRT90: undefined,
      searchSWEREF99: undefined,
      searchUNIT: undefined
    };
  }

  render() {
    const { strings } = this.props;

    return (
      <div>
        <TextField
          id="rt90"
          label={strings.searchRT90}
          type="text"
          value={this.state.searchRT90}
          onKeyDown={this.onRT90KeyDown}
        />
        <TextField
          id="sweref99"
          label={strings.searchSWEREF99}
          type="text"
          value={this.state.searchSWEREF99}
          onKeyDown={this.onSWEREF99KeyDown}
        />
        <TextField
          id="unit"
          label={strings.searchUNIT}
          type="text"
          value={this.state.searchUNIT}
          onKeyDown={this.onUnitKeyDown}
        />
      </div>
    );
  }

  onRT90KeyDown = e => {
    if (e.keyCode === 13) {
      this.onSearch("rt90", e.target.value);
    }
    this.setState({ searchRT90: e.target.value });
  };

  onSWEREF99KeyDown = e => {
    if (e.keyCode === 13) {
      this.onSearch("sweref99", e.target.value);
    }
    this.setState({ searchSWEREF99: e.target.value });
  };

  onUnitKeyDown = e => {
    if (e.keyCode === 13) {
      this.props.updateUnitLocation(e.target.value);
    }
    this.setState({ searchUNIT: e.target.value });
  };

  onClear = () => {
    this.setState({
      searchRT90: undefined,
      searchSWEREF99: undefined,
      searchUNIT: undefined
    });
  };

  /*
   * Henter koordinater for lokasjonenen, og gjør en callback for å vise
   * informasjonen i kartet
   */
  onSearch = (type, search) => {
    let latLng = search.split(",");

    fetch(API + `/api/v1.0/Coordinates/Get/${type}/${latLng[0]}/${latLng[1]}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("access_token")
      }
    })
      .then(response => {
        if (response.ok) {
          // Request success
          response.json().then(result => {
            const object = {
              latitude: result.wgs84Latitude,
              longitude: result.wgs84Longitude,
              name: result.address,
              formatted_address: result.address,
              details: result,
              label: this.getLabel(result)
            };
            this.props.callback(object);
          });
        } else {
          // Request failed
          response.text().then(result => {
            helpers.notifyGenericError(this.props.strings, result);
          });
        }
      })
      .catch(error => {});
  };

  getLabel = object => {
    return (
      <div>
        <table border="0">
          <tbody>
            <tr>
              <td>
                <b>Address</b>
              </td>
              <td>{object.address}</td>
            </tr>
            <tr>
              <td>
                <b>WSG84</b>
              </td>
              <td>
                {object.wgs84Latitude},{object.wgs84Longitude}
              </td>
            </tr>
            <tr>
              <td>
                <b>RT90</b>
              </td>
              <td>
                {object.rt90Latitude},{object.rt90Longitude}
              </td>
            </tr>
            <tr>
              <td>
                <b>SweRef</b>
              </td>
              <td>
                {object.sweRefLatitude},{object.sweRefLongitude}
              </td>
            </tr>
          </tbody>
        </table>

        <div style={{ textAlign: "right" }}>
          <Icon
            iconName="Save"
            onClick={() => {
              this.props.onSavePOICallback(
                object.wgs84Latitude,
                object.wgs84Longitude
              );
            }}
          />
        </div>
      </div>
    );
  };
}
