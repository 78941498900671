import * as React from "react";

export default {
  provideNumber: "Ange nummer",
  trackingInterval: "Tidsintervall för spårning ",
  positionInterval: "Intervall för positionering (min)",
  abort: "Avbryta",
  activeUnit: "aktiv enhet",
  add: "Lägg til",
  address: "adress",
  addRole: "Lägg till ny roll",
  addToGroup: "Lägg til i grupp",
  all: "Alla",
  and: " och ",
  categories: "Littera",
  centerMap: "centrum karta",
  changePassword: "Ändra lösenord",
  close: "Stäng",
  companies: "företag",
  company: "bolag",
  companyPublicPOIs: "Bolag POIs",
  clearTracking: "Ta bort spårning",
  createPoi: "Nytt interessepunkt",
  createPoiFromMap: "Spara interessepunkt",
  createPoiShort: "Ny POI",
  customer: "kund",
  customerNumber: "Kundenummer",
  date: "datum",
  delete: "Radera",
  deleteGroup: "Radera grupp",
  deleteUser: "Radera användare",
  deleteUserMessage: "Är du säker på att du vill radera användaren",
  deliveryDate: "Datum",
  deliveryTime: "Tid",
  description: "Beskrivning",
  distanceInKm: "Distans i Km",
  driver: "chaufför",
  driverNumber: "Åkarnummer",
  edit: "Redigera",
  editUser: "Redigera användare",
  emailLabel: "Epost",
  employeeNumber: "anställningsnummer",
  endTime: "Sluttid",
  errorMessageMissingAkarNo: "Åkarnummer kan bara vara siffror",
  errorMessageMissingCompany: "Företag saknas",
  errorMessageMissingCustomerNo: "Kundnummer kan bara vara siffror",
  errorMessageMissingEmail: "E-post saknas",
  errorMessageMissingName: "Namn saknas",
  errorMessageMissingPassword: "Lösenord saknas",
  errorMessageMissingPhoneNo: "Telefon saknas",
  errorMessageMissingRole: "Roll saknas",
  errorMessageMissingUsername: "Användarnamn saknas",
  errorMessagePasswordMismatch: "Lösenord matchar inte",
  errorMessageNetworkError: message => {
    return `Det gick inte att hämta ${message}. Det kan tyckas vara ett nätverksproblem med TDX-servern. Försök igen om några minuter.`;
  },
  errorMessageGeneric: message => {
    return `Ai, ett fel har inträffat! - Felmeddelande ${message}`;
  },
  errorMessageNoCoordinatesFound: unit => {
    return `Det går inte att visa enheten ${unit} i kartan eftersom vi inte har fått några koordinater på den.`;
  },

  errorMessageNoTrackingFound: (
    type,
    trackingId,
    interval,
    fromdate,
    todate
  ) => {
    let unitType = type === "order" ? "order" : "enhet";

    return (
      <div>
        Vi hittade ingen GPS-historia på {unitType} <b>{trackingId}</b> under
        perioden <b>{fromdate}</b> till <b>{todate}</b>
      </div>
    );
  },

  followUnit: "Följ enhet",
  from: "från",
  fromDate: "från datum",
  groups: "grupper",
  hide: "dölj",
  history: "historik",
  lastLogin: "senaste inlogging",
  locate: "lokalisering",
  locateInMap: "Lokalisering på karta",
  login: "Login",
  loginExpired:
    "Din session har snart löpt ut, logga in med ditt användarnamn och lösenord för att undvika att du förlorar din session.",
  loginHeaderText: "Pålogg inloggning rubrik text",
  loginInProgress: "Loggar på",
  loadingCompanies: "Hämtar bolag",
  logout: "Logga ut",
  map: "karta",
  mapsettings: "Kartinställningar",
  materialPlaces: "Materialplatser",
  me: "Mig",
  mine: "Mina",
  mission: "Uppdrag",
  month: "månad",
  myPOIs: "Mina POIs",
  name: "Namn",
  new: "Ny",
  newGroup: "Ny grupp",
  newRole: "Ny roll",
  newUser: "Ny användar",
  notresponsiveheader: "Meddelande",
  notresponsivetext: "This page is not created for mobile devices.",
  noUnitsApplicable: "Inga tillgängliga enheter ",
  noCompaniesApplicable: "Inga tillgängliga bolag ",
  office: "kontor",
  offices: "kontor",
  ok: "OK",
  order: "order",
  orderInformation: "Orderinformation",
  orderList: "Orderlista",
  orderNo: "Ordernr",
  orderStatusTimeStamp: "Senaste statusändring",
  passwordLabel: "Lösenord",
  passwordRetypeLabel: "Bekräfta lösenord",
  phoneLabel: "Telefon",
  places: "Platser",
  poi: "POI",
  poiDropdownPlaceHolder: "Bearbetnings och materialplatser",
  pois: "POIs",
  posAge: "Senaste position",
  posInformation: "Positionsinformation",
  processingPlaces: "Bearbetningsplatser",
  reference: "Referens",
  reload: "ladda om",
  role: "roll",
  save: "Spara",
  savedPOIs: "Sparade intressepunkter",
  saveGroup: "Spara grupp",
  search: "sök",
  searchAddress: "Sök adress/ort",
  searchRT90: "Sök RT90 koordinater",
  searchSWEREF99: "Sök SWEREF99 ...",
  searchUNIT: "Sök enhet",
  select: "välj",
  selectAll: "välj alla",
  selectCompany: "Välj bolag",
  selectDate: "Välj et datum",
  selectedUnits: "Väljade enheter",
  selection: "Urval",
  selectLanguage: "Välj språk",
  selectNone: "Välj inget ",
  selectOfficeOrGroup: "Välj kontor eller grupper",
  selectUnits: "Välj enhet",
  setSelectedUnit: "Sätt som vald enhet",
  show: "visa",
  showAllUnitsInMap: "Visa alla enheter på kartan",
  showInMap: "Visa på kartan",
  speed: "hastighet",
  startTime: "Starttid",
  status: "status",
  tdx: "TDX",
  to: "till",
  toDate: "till datum",
  total: "totala",
  time: "tid",
  tracking: "spårning",
  track: "spåra",
  unit: "enhet",
  unitCategory: "Enhetskategori",
  unitHistory: "Enhetshistorik",
  unitInformation: "Enhetsinformation",
  units: "enheter",
  useradministration: "Användaradministration",
  usernameLabel: "Användarnamn",
  userSettingsHeaderText: "Användarinställningar",
  version: "Version",
  visible: "Synlig för",
  waybill: "Fraktsedel",
  yes: "Ja",
  zoomToActiveUnit: "Zooma till aktiv enhet",
  loading: "Laddar data",
  loadingFromTdx: "Laddar data från TDX",
  zoomToUnit: "Zooma till enhet"
};
