import React, { Component } from "react";
import { ContextualMenu, DirectionalHint } from "office-ui-fabric-react/lib/ContextualMenu";

export default class UnitContextualMenu extends Component {
  render() {
    return (
      <div>
        {!this.props.isHidden &&
          <ContextualMenu
            target={this.props.target}
            directionalHint={DirectionalHint.bottomLeftEdge}
            onDismiss={this.props.onDismiss}
            items={this.props.items}
          />}
      </div>
    );
  }
}
