import * as React from "react";
// import DevTools from "components/DevTools";
import { initializeIcons } from "@uifabric/icons";

import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ToastContainer } from "react-toastify";
import translate from "i18n/Translate";
import { connect } from "react-redux";
import { mapDispatchToProps } from "actions/mapDispatchToProps";
import * as APPVAR from "utils/constants";

import MapContainer from "./Map/MapContainer";
import LoginForm from "components/LoginForm";
import Menu from "components/Menu";
import UserAdministration from "./UserAdministration";
import "react-toastify/dist/ReactToastify.css";
import "styles/gridsystem.css";
import "styles/index.css";
import "styles/locus.css";
import "styles/animate.css";

initializeIcons(undefined, { disableWarnings: true });
console.clear();

const mapStateToProps = state => {
  return state;
};

/**
 * Entrypoint setting up layouts
 *
 * @export
 * @class Layout
 * @extends {React.Component}
 */
class Layout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      clearForm: undefined,
      items: {}
    };
  }

  componentDidMount = () => {
    this.props.validateUser();
  };

  render() {
    const { layout } = this.props.application;

    if (this.props.authentication.isAuthenticated) {
      return (
        <div className="ms-Fabric container-fluid ms-slideDownIn20">
          <Helmet>
            <title>{APPVAR.APPLICATION_NAME}</title>
          </Helmet>
          <ToastContainer
            className="modifiedToast"
            position="top-left"
            autoClose={4000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange
            pauseOnHover
            draggable={false}
          />
          <div className="row">
            <div className="col no-padding">
              <Menu {...this.props} onLogoClick={this.state.clearForm} />
            </div>
          </div>
          <div className="row">
            <div className="col no-padding">
              <UserAdministration />
              <MapContainer clearForm={this.registerClearForm} />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <LoginForm
          layout={layout}
          loginAction={this.props.loginUser}
          preLoginAction={this.props.preLoginUser}
          companies={this.props.companies}
          errormessage={this.props.authentication.message}
          {...this.props.authentication}
        />
      );
    }
  }

  /* 
    Gross hack to get access to clearForm function 
    inside MapContainer without using Ref.
  */
  registerClearForm = callback => {
    this.setState({ clearForm: callback });
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(translate()(Layout))
);
