import React, { Component } from "react";
import {
  ContextualMenu,
  DirectionalHint
} from "office-ui-fabric-react/lib/ContextualMenu";
import * as helpers from "utils/functions";
import { ContextualMenuItemType } from "office-ui-fabric-react/lib/ContextualMenu";

export default class MapContextualMenu extends Component {
  render() {
    const { strings } = this.props;
    this.items = [
      {
        key: "mapSection",
        itemType: ContextualMenuItemType.Section,
        sectionProps: {
          key: "mapSectionProps",
          topDivider: true,
          bottomDivider: true,
          title: helpers.Cap(strings.map),
          items: [
            {
              key: "createPoi",
              name: helpers.Cap(strings.createPoi),
              onClick: () => {
                this.props.createPoiFromMapClick();
              }
            },
            {
              key: "locateInMap",
              name: strings.locateInMap,
              onClick: () => {
                this.props.activeUnit &&
                  this.props.unitsRefs[this.props.activeUnit] &&
                  this.props.unitsRefs[this.props.activeUnit].locateUnit();
              }
            },
            {
              key: "zoomToUnit",
              name: strings.zoomToActiveUnit,
              disabled: this.props.activeUnit ? false : true,
              onClick: () => {
                this.props.setMapCenter(this.props.activeUnit, 15);
              }
            }
          ]
        }
      }
    ];
    return (
      <div>
        {!this.props.isHidden && (
          <ContextualMenu
            target={this.props.target}
            directionalHint={DirectionalHint.bottomLeftEdge}
            onDismiss={this.props.onDismiss}
            items={this.items}
          />
        )}
      </div>
    );
  }
}
