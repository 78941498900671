import React, { Component } from "react";
import * as helpers from "utils/functions";
import moment from "moment";
import { API } from "utils/api";
import { Spinner, SpinnerSize } from "office-ui-fabric-react/lib/Spinner";
import * as _ from "lodash";

export default class ActiveUnit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lastPosGeoCode: "",
      unit: this.props.unit,
      unitOrderInfo: this.props.unitOrderInfo,
      orderInfo: [],
      selectedOrderIds: []
    };
  }

  componentDidUpdate = oldProps => {
    if (this.props.activeUnit !== oldProps.activeUnit) {
      this.loadUnitInformation(this.props.activeUnit);
    }
  };

  componentDidMount = () => {
    this.loadUnitInformation(this.props.activeUnit);
  };

  render() {
    const { unitPosition, strings } = this.props;
    const { orderInfo, unitOrderInfo, unit, loading } = this.state;

    if (loading) {
      return (
        <div>
          <Spinner size={SpinnerSize.large} label={strings.loadingFromTdx} />
        </div>
      );
    }

    if (!unit || !unitPosition) {
      return null;
    }

    return (
      <div style={{ marginTop: 5, marginBottom: 5 }}>
        {/* <Card header={helpers.Cap(strings.unit) + " " + unit.radioNumber}> */}
        {/* <p className="ms-fontSize-xl">{strings.unitInformation}</p> */}
        <table style={{ color: "black", fontSize: "16px", width: "100%" }}>
          <tbody>
            {/* Position */}
            <tr>
              <td
                colSpan="2"
                style={{ paddingTop: 15, paddingBottom: 5 }}
                className="ms-fontSize-xl"
              >
                {strings.posInformation}
              </td>
            </tr>
            <tr>
              <td
                style={{ width: 100, wordWrap: "break-word" }}
                className="ms-fontSize-l"
              >
                {helpers.Cap(strings.posAge)}
              </td>
              <td style={{ whiteSpace: "nowrap" }} className="ms-fontSize-m">
                {unitPosition && moment(unitPosition.timeStamp).format("LLL")} (
                {unitPosition && moment(unitPosition.timeStamp).fromNow()})
              </td>
            </tr>
            <tr>
              <td
                style={{ width: 100, wordWrap: "break-word" }}
                className="ms-fontSize-l"
              >
                {helpers.Cap(strings.speed)}
              </td>
              <td className="ms-fontSize-m">
                {unitPosition && unitPosition.speed} km/h
              </td>
            </tr>
            <tr>
              <td
                style={{ width: 100, wordWrap: "break-word" }}
                className="ms-fontSize-l"
              >
                {helpers.Cap(strings.address)}
              </td>
              <td className="ms-fontSize-m">
                {unitPosition && unitPosition.address}
                <br />
                <div className="ms-fontSize-s">
                  {unitPosition &&
                    `${unitPosition.latitude} , ${unitPosition.longitude}`}
                </div>
              </td>
            </tr>

            {/* orderInformation */}

            <tr>
              <td
                colSpan="2"
                style={{ paddingTop: 15, paddingBottom: 5 }}
                className="ms-fontSize-xl"
              >
                {strings.orderInformation}
              </td>
            </tr>
            <tr>
              <td
                style={{ width: 150, wordWrap: "break-word" }}
                className="ms-fontSize-l"
              >
                {helpers.Cap(strings.driver)}
              </td>
              <td style={{ whiteSpace: "nowrap" }} className="ms-fontSize-m">
                {unitPosition && unitPosition.driver}
              </td>
            </tr>
            <tr>
              <td
                style={{ width: 100, wordWrap: "break-word" }}
                className="ms-fontSize-l"
              >
                {helpers.Cap(strings.unitCategory)}
              </td>
              <td style={{ whiteSpace: "nowrap" }} className="ms-fontSize-m">
                {unit && unit.enhKatText}
              </td>
            </tr>
            <tr>
              <td
                style={{ width: 100, wordWrap: "break-word" }}
                className="ms-fontSize-l"
              >
                {helpers.Cap(strings.order)}
              </td>
              <td style={{ whiteSpace: "nowrap" }} className="ms-fontSize-m">
                {unitOrderInfo && unitOrderInfo.orderNr}
              </td>
            </tr>
            <tr>
              <td style={{ width: 100 }} className="ms-fontSize-l">
                {helpers.Cap(strings.orderStatusTimeStamp)}
              </td>
              <td style={{ whiteSpace: "nowrap" }} className="ms-fontSize-m">
                {unitOrderInfo && unitOrderInfo.orderTimeStamp}
              </td>
            </tr>
            <tr>
              <td
                style={{ width: 100, wordWrap: "break-word" }}
                className="ms-fontSize-l"
              >
                {helpers.Cap(strings.status)}
              </td>
              <td style={{ whiteSpace: "nowrap" }} className="ms-fontSize-m">
                {unitOrderInfo && unitOrderInfo.orderStatusText}
              </td>
            </tr>
            <tr>
              <td
                style={{ width: 100, wordWrap: "break-word" }}
                className="ms-fontSize-l"
              >
                {helpers.Cap(strings.customer)}
              </td>
              <td style={{ whiteSpace: "nowrap" }} className="ms-fontSize-m">
                {unitOrderInfo && unitOrderInfo.customer}
              </td>
            </tr>

            {/* Order List */}

            <tr>
              <td
                colSpan="2"
                style={{ paddingTop: 15, paddingBottom: 5 }}
                className="ms-fontSize-xl"
              >
                {strings.orderList}
              </td>
            </tr>

            <tr>
              <td
                colSpan="2"
                style={{
                  cursor: "pointer",
                  overflowX: "auto"
                }}
              >
                {orderInfo.map((order, ix) => {
                  return (
                    <div
                      key={ix}
                      onClick={() => this.onOrderInfoClick(order.orderNumber)}
                    >
                      <table style={{ width: "100%" }}>
                        <tbody>
                          <tr>
                            <td style={{ textAlign: "center" }}>
                              <span
                                className="badge"
                                style={{
                                  backgroundColor: this.getStatusColor(
                                    order.statusNr
                                  )
                                }}
                              />
                            </td>
                            <td>
                              <b>{strings.orderNo}</b>: {order.orderNumber}
                            </td>
                          </tr>

                          {this.state.selectedOrderIds &&
                            _.findIndex(this.state.selectedOrderIds, o => {
                              return o === order.orderNumber;
                            }) > -1 && (
                              <tr>
                                <td
                                  colspan="2"
                                  style={{
                                    backgroundColor: "rgba(0,0,0,0.1)",
                                    overflowX: "hidden"
                                  }}
                                >
                                  <table
                                    style={{
                                      width: "100%",
                                      marginLeft: "20px",
                                      marginBottom: "15px"
                                    }}
                                  >
                                    <tbody className="ms-fontSize-m">
                                      {order.clientCompanyNumber && (
                                        <tr>
                                          <td className="ms-fontSize-l">
                                            {helpers.Cap(strings.customer)}
                                          </td>
                                          <td>{`${order.clientCompanyNumber} ${
                                            order.clientName
                                          }`}</td>
                                        </tr>
                                      )}
                                      {order.wayBills.length > 0 && (
                                        <tr>
                                          <td className="ms-fontSize-l">
                                            {strings.waybill}
                                          </td>
                                          <td>
                                            {order.wayBills.map(i => {
                                              return <div>{i}</div>;
                                            })}
                                          </td>
                                        </tr>
                                      )}
                                      {order.littera && (
                                        <tr>
                                          <td className="ms-fontSize-l">
                                            {helpers.Cap(strings.categories)}
                                          </td>
                                          <td>{order.littera}</td>
                                        </tr>
                                      )}

                                      {order.categories && (
                                        <tr>
                                          <td className="ms-fontSize-l">
                                            {helpers.Cap(strings.reference)}
                                          </td>
                                          <td>{order.categories}</td>
                                        </tr>
                                      )}
                                      {order.levDate && (
                                        <tr>
                                          <td className="ms-fontSize-l">
                                            {helpers.Cap(strings.deliveryDate)}
                                          </td>
                                          <td>{order.levDate}</td>
                                        </tr>
                                      )}
                                      {order.levTime && (
                                        <tr>
                                          <td className="ms-fontSize-l">
                                            {helpers.Cap(strings.deliveryTime)}
                                          </td>
                                          <td>{order.levTime}</td>
                                        </tr>
                                      )}
                                      {order.mission && (
                                        <tr>
                                          <td className="ms-fontSize-l">
                                            {helpers.Cap(strings.mission)}
                                          </td>
                                          <td>{order.mission}</td>
                                        </tr>
                                      )}
                                      {order.orderEnhKat && (
                                        <tr>
                                          <td className="ms-fontSize-l">
                                            {helpers.Cap(strings.unitCategory)}
                                          </td>
                                          <td>{order.orderEnhKat}</td>
                                        </tr>
                                      )}
                                      {order.fromAdress && (
                                        <tr>
                                          <td className="ms-fontSize-l">
                                            {helpers.Cap(strings.from)}
                                          </td>
                                          <td>{order.fromAdress}</td>
                                        </tr>
                                      )}
                                      {order.toAdress && (
                                        <tr>
                                          <td className="ms-fontSize-l">
                                            {helpers.Cap(strings.to)}
                                          </td>
                                          <td>{order.toAdress}</td>
                                        </tr>
                                      )}
                                      {order.statusNr && (
                                        <tr>
                                          <td className="ms-fontSize-l">
                                            {helpers.Cap(strings.status)}
                                          </td>
                                          <td>{order.statusNr}</td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            )}
                        </tbody>
                      </table>
                    </div>
                  );
                })}
              </td>
            </tr>
          </tbody>
        </table>
        {/* </Card> */}
      </div>
    );
  }

  getStatusColor = statusCode => {
    switch (parseInt(statusCode, 10)) {
      case 22:
        return "#ffff00";
      case 23:
      case 24:
        return "#00ff00";
      default:
        return "#0000ff";
    }
  };
  onOrderInfoClick = orderId => {
    const { selectedOrderIds } = this.state;
    let index = _.findIndex(selectedOrderIds, o => {
      return o === orderId;
    });
    if (index > -1) {
      selectedOrderIds.splice(index, 1);
    } else {
      selectedOrderIds.push(orderId);
    }
    this.setState({ selectedOrderIds });
  };

  loadUnitInformation = unitId => {
    this.setState({ loading: true });
    return fetch(`${API}/api/v1.0/Units/Get/ActiveUnitDetails/${unitId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("access_token")
      }
    })
      .then(response => {
        if (response.ok) {
          // Request success
          response.json().then(result => {
            let unitOrderInfo = result.geoData[0];
            let unit = result.vehicle;
            this.setState({
              unit,
              unitOrderInfo
            });
            let orders = _.concat(
              unitOrderInfo.currentActiveOrderNumbers,
              unitOrderInfo.status22OrderNumbers,
              unitOrderInfo.status23OrderNumbers
            );
            this.loadOrderInfo(
              _.sortBy(orders, o => {
                return o.orderNumber;
              })
            );
          });
        } else {
          // Request failed
          response.text().then(result => {
            this.setState({ loading: false });
            helpers.notifyError(this.props.strings.errorMessageGeneric(result));
          });
        }
      })
      .catch(error => {
        this.setState({ loading: false });
      });
  };

  loadOrderInfo = orderIds => {
    if (orderIds.length > 0) {
      return fetch(`${API}/api/v1.0/Orders/Get/Orders/${orderIds.slice()}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "bearer " + localStorage.getItem("access_token")
        }
      })
        .then(response => {
          if (response.ok) {
            // Request success
            response.json().then(result => {
              this.setState({
                orderInfo: _.sortBy(result, o => {
                  return o.orderNumber;
                }),
                loading: false
              });
            });
          } else {
            // Request failed
            response.text().then(result => {
              this.setState({ loading: false });
              helpers.notifyGenericError(this.props.strings, result);
            });
          }
        })
        .catch(error => {
          helpers.notifyGenericError(this.props.strings, error);
        });
    } else {
      this.setState({
        loading: false,
        orderInfo: []
      });
    }
  };
}
