import moment from "moment";
// Action
export const isLoading = loading => {
  return {
    type: "IS_LOADING",
    isLoading: loading
  };
};

export const toggleUserSettings = state => {
  return {
    type: "TOGGLE_USERSETTINGS",
    showUserSettings: state
  };
};

export const toggleUserNotification = state => {
  return {
    type: "TOGGLE_USERNOTIFICATION",
    showUserNotifications: state
  };
};

export const addNotification = message => {
  return {
    type: "ADD_USERNOTIFICATION",
    message: message,
    logtime: moment()
  };
};

export const setLanguage = lang => {
  return {
    type: "SET_LANGUAGE",
    currentLanguage: lang
  };
};
