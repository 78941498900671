import React, { Component } from "react";
import { Modal } from "office-ui-fabric-react/lib/Modal";
import {
  DefaultButton,
  PrimaryButton
} from "office-ui-fabric-react/lib/Button";
import { TextField } from "office-ui-fabric-react/lib/TextField";
import { Dropdown } from "office-ui-fabric-react/lib/Dropdown";
import { withGoogleMap, GoogleMap } from "react-google-maps";

import { connect } from "react-redux";
import { mapDispatchToProps } from "actions/mapDispatchToProps";

import PoiMapObject from "components/Map/PoiMapObject/PoiMapObject";
import { API } from "utils/api";
import * as helpers from "utils/functions";
import "./NewPoiForm.css";

const mapStateToProps = state => {
  return state;
};

const GoogleMapComponent = withGoogleMap(props => {
  return (
    <GoogleMap
      zoom={props.zoom}
      defaultCenter={{ lat: props.center.lat, lng: props.center.lng }}
      center={{ lat: props.center.lat, lng: props.center.lng }}
      defaultOptions={{
        fullscreenControl: false,
        mapTypeControl: false,
        streetViewControl: false
      }}
    >
      {props.children}
    </GoogleMap>
  );
});

class NewPoiForm extends Component {
  constructor(props) {
    super(props);

    this.options = [
      {
        key: 0,
        text: this.props.strings.me
      },
      {
        key: 1,
        text: this.props.strings.all
      }
    ];

    this.initialState = {
      position: undefined,
      description: undefined,
      locationInfo: undefined,
      visibility: 0
    };

    this.state = this.initialState;
  }

  componentDidMount = () => {
    this.getAddressFromCoordinates(this.props.newPoi);
  };

  componentWillUpdate = (nextProps, nextState) => {
    if (nextProps.newPoi !== this.props.newPoi) {
      this.getAddressFromCoordinates(nextProps.newPoi);
    }
  };

  render() {
    const { strings, newPoi } = this.props;
    const { position, description } = this.state;

    // Sett values based on props or state. State if mapmarker have changed lo
    let mapCenter = position
      ? { lat: position.lat(), lng: position.lng() }
      : { lat: newPoi.lat, lng: newPoi.lng };
    let poi = position
      ? { latitude: position.lat(), longitude: position.lng() }
      : { latitude: newPoi.lat, longitude: newPoi.lng };
    let label = position
      ? `(${position.lat().toString()}, ${position.lng().toString()})`
      : `(${this.props.newPoi.lat}, ${this.props.newPoi.lng})`;

    return (
      <Modal
        containerClassName="NewPoiFormModalContainer ms-scaleUpIn100"
        isOpen={this.props.showModal}
        onDismiss={this.props.onDismiss}
        isBlocking={false}
      >
        <div className="NewPoiFormContainer ">
          <div className="NewPoiFormHeader">
            <font className="ms-font-xxl">{strings.createPoiFromMap}</font>
          </div>
          <div className="NewPoiFormContent container">
            <div className="row">
              <div className="col-5">
                <TextField
                  label={strings.name}
                  iconProps="ms-Icon--Permissions ms-Icon"
                  type="text"
                  onGetErrorMessage={value => this.validateInput(value, "name")}
                  onChanged={value => this.setState({ title: value })}
                  validateOnFocusOut={true}
                  validateOnLoad={false}
                />

                <TextField
                  style={{ height: "200px" }}
                  label={strings.description}
                  iconProps="ms-Icon--Permissions ms-Icon"
                  type="text"
                  // onGetErrorMessage={(value) => this.validateInput(value, "description")}
                  validateOnFocusOut={true}
                  validateOnLoad={false}
                  value={description}
                  onChanged={value => this.setState({ description: value })}
                  multiline
                />

                <Dropdown
                  label={strings.visible}
                  options={this.options}
                  onChanged={value => this.setState({ visibility: value.key })}
                  defaultSelectedKey={0}
                />
              </div>
              <div className="col-7">
                <GoogleMapComponent
                  zoom={17}
                  containerElement={
                    <div style={{ height: `100%`, width: `100%` }} />
                  }
                  mapElement={<div style={{ height: `100%`, width: `100%` }} />}
                  center={mapCenter}
                >
                  <PoiMapObject
                    object={poi}
                    draggable
                    onDragEnd={this.markerPositionUpdate}
                  />
                </GoogleMapComponent>
                <font className="ms-font-s">{label}</font>
              </div>
            </div>
          </div>
          <div className="NewPoiFormFooter">
            <DefaultButton
              className="NewPoiFormButton"
              description=""
              onClick={this.onDismiss}
              text={strings.abort}
            />
            <PrimaryButton
              className="NewPoiFormButton"
              description=""
              onClick={() => this.onSave(poi)}
              text={strings.save}
            />
          </div>
        </div>
      </Modal>
    );
  }
  markerPositionUpdate = position => {
    this.setState({ position: position.latLng });
    this.getAddressFromCoordinates({
      lat: position.latLng.lat().toString(),
      lng: position.latLng.lng().toString()
    });
  };

  getAddressFromCoordinates = position => {
    if (position) {
      return fetch(
        API + `/api/v1.0/Coordinates/Get/${position.lat}/${position.lng}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "bearer " + localStorage.getItem("access_token")
          }
        }
      )
        .then(response => {
          if (response.ok) {
            // Request success
            response.json().then(result => {
              this.setState({ description: result.address });
            });
          } else {
            // Request failed
            response.text().then(result => {
              helpers.notifyGenericError(this.props.strings, result);
            });
          }
        })
        .catch(error => {});
    }
  };

  validateInput = (value, inputType) => {
    return "";
  };

  onDismiss = () => {
    this.setState(this.initialState);
    this.props.onDismiss();
  };

  onSave = poi => {
    let poiObject = {
      POIName: this.state.title,
      POIBeskrivning: this.state.description,
      Latitude: poi.latitude,
      Longitude: poi.longitude,
      CompanyPublic: this.state.visibility
    };
    // --
    return fetch(API + `/api/v1.0/POI/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("access_token")
      },
      body: JSON.stringify(poiObject)
    })
      .then(response => {
        if (response.ok) {
          this.props.loadUserPOIs(this.props.strings, this.props.onDismiss);
        } else {
          // Request failed
          helpers.notifyGenericError(this.props.strings, response.statusText);
        }
      })
      .catch(error => {
        helpers.notifyGenericError(this.props.strings, error);
      });
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewPoiForm);
