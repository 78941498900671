/* global google */
// https://developers.google.com/maps/documentation/javascript/tutorial
import React, { Component } from "react";

import translate from "i18n/Translate";
import { MENU_HEIGHT_IN_PX } from "utils/constants";
import _ from "lodash";
import moment from "moment";
import { withGoogleMap, GoogleMap, TrafficLayer } from "react-google-maps";
import Card from "components/Card/Card";
// import MapCard from "components/MapCard/MapCard";
import Drawer from "components/Drawer/Drawer";
import { CommandBar } from "office-ui-fabric-react/lib/CommandBar";
import VehicleMapObject from "components/Map/VehicleMapObject/VehicleMapObject";
import PoiMapObject from "components/Map/PoiMapObject/PoiMapObject";
import Tracking from "components/Map/Tracking/Tracking";
import MapControl from "components/Map/MapControl/MapControl";
import MapContentPicker from "./MapContentPicker/MapContentPicker";
import MapContextualMenu from "components/ContextualMenu/Map/MapContextualMenu";
import ActiveUnit from "components/Map/ActiveUnit/ActiveUnit";
import NewPoiForm from "containers/Forms/NewPoi/NewPoiForm";
import * as helpers from "utils/functions";
import { API } from "utils/api";
import HelpScreen from "components/HelpScreen/HelpScreen";
import { toast } from "react-toastify";

const GoogleMapComponent = withGoogleMap(props => {
  return (
    <GoogleMap
      zoom={props.zoom}
      options={{
        maxZoom: 18,
        mapTypeControl: true,
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
          mapTypeIds: ["roadmap", "terrain"]
        }
      }}
      defaultCenter={{ lat: props.center.lat, lng: props.center.lng }}
      center={{ lat: props.center.lat, lng: props.center.lng }}
      onCenterChanged={props.updateCenter}
      ref={props.onMapMounted}
      mapTypeId={"roadmap"}
      onRightClick={props.onRightClick}
      onZoomChanged={props.onZoomChanged}
    >
      <TrafficLayer autoUpdate />

      {props.children}
    </GoogleMap>
  );
});

export default translate()(
  class MapContainer extends Component {
    constructor(props) {
      super(props);
      this._map = undefined;
      this.mapDiv = undefined;
      this.mapContentPicker = undefined;
      this.unitsRef = [];

      this.initialState = {
        docked: true,
        open: true,
        transitions: true,
        hideMap: false,
        touch: true,
        enableDragHandle: true,
        position: "left",
        dragToggleDistance: 30,
        mapBounds: [],
        nextZoom: 15,
        zoom: 6,
        radius: 45,
        showInfo: false,
        mapCenter: {
          lat: 59.3558828,
          lng: 17.972331
        },
        distanceKm: 0,
        trailingHistoryInMinutes: 15,
        trackUnit: false,
        mapHeightInPx: window.innerHeight - MENU_HEIGHT_IN_PX,
        activeUnit: undefined,
        menuItem: "tdx",
        location: {
          id: "0",
          comUnitId: "353438061887860",
          unitType: "Aplicom A1",
          latitudeDeg: 59.3558828,
          longitudeDeg: 17.972331,
          speed: "0",
          received: "01.01.2017 00:00:00"
        },
        poiInMap: [],
        placeInMap: [],
        selectedUnits: [],
        unitsPositions: [],
        historyFrom: "10.01.2017",
        historyTo: "10.07.2017",
        showMapContextualMenu: false,
        mapTargetPoint: { x: 0, y: 0 },
        showNewPoiForm: false,
        showUnitInfo: false,
        showHelpScreen: false,
        newPoi: {
          lat: 0,
          lng: 0
        },
        addressSearchObject: {
          latitude: undefined,
          longitude: undefined,
          name: undefined,
          formatted_address: undefined
        },
        activeUnitOrderInfo: [],
        trackingInfo: [],
        clearMapContentPicker: undefined
      };

      this.state = this.initialState;
    }

    componentDidMount = () => {
      this.props.clearForm(this.clearForm);
      this.updateWindowDimensions();
      window.addEventListener("resize", this.updateWindowDimensions);
      window.addEventListener("keydown", this.keyDownBinding);
    };

    componentWillUnmount = () => {
      clearInterval(this.countdown);
      window.removeEventListener("resize", this.updateWindowDimensions);
      window.removeEventListener("keydown", this.keyDownBinding);
    };

    render() {
      const { strings } = this.props;
      moment.locale(this.props.currentLanguage);
      const drawerProps = {
        docked: this.state.docked,
        open: this.state.open,
        touch: this.state.touch,
        enableDragHandle: this.state.enableDragHandle,
        position: this.state.position,
        dragToggleDistance: this.state.dragToggleDistance,
        transitions: this.state.transitions,
        onOpenChange: this.onOpenChange
      };

      return (
        <div>
          <NewPoiForm
            strings={strings}
            newPoi={this.state.newPoi}
            showModal={this.state.showNewPoiForm}
            onDismiss={this.onNewPoiFormDismiss}
          />

          {this.state.showHelpScreen && (
            <HelpScreen
              isOpen={this.state.showHelpScreen}
              strings={strings}
              onDismiss={this.onHelpDismiss}
            />
          )}

          <CommandBar
            isSearchBoxVisible={false}
            items={[
              {
                key: "tdx",
                name: strings.tdx,
                onClick: () => {
                  this.setActiveMenuItem("tdx");
                }
              },
              {
                key: "groups",
                name: helpers.Cap(strings.groups),

                onClick: () => {
                  this.setActiveMenuItem("groups");
                }
              },
              {
                key: "poi",
                name: helpers.Cap(strings.poi),
                onClick: () => {
                  this.setActiveMenuItem("poi");
                }
              },
              {
                key: "search",
                name: helpers.Cap(strings.search),
                onClick: () => {
                  this.setActiveMenuItem("search");
                }
              },
              {
                key: "tracking",
                name: helpers.Cap(strings.tracking),
                onClick: () => {
                  this.clearActiveUnit();
                  this.clearUnitSearchObject();
                  this.setState({
                    selectedUnits: [],
                    unitsPositions: []
                  });
                  this.setActiveMenuItem("tracking");
                }
              }
              // {
              //   key: "history",
              //   name: helpers.Cap(strings.history),
              //   onClick: () => {
              //     this.setActiveMenuItem("history");
              //   }
              // }
            ]}
            farItems={[
              {
                key: "centerMap",
                icon: "MapPin",
                name: helpers.Cap(strings.centerMap),
                disabled:
                  this.state.mapBounds && this.state.mapBounds.length === 0
                    ? true
                    : false,
                onClick: () => {
                  this.state.mapBounds && this.setMapBounds();
                }
              }
            ]}
          />
          <div className="row">
            <Drawer
              sidebar={
                <MapContentPicker
                  registerClearForm={this.registerClearForm}
                  mapRef={this._map}
                  onDockerChange={this.onDock}
                  activeMenu={this.state.menuItem}
                  updateUnitLocation={this.updateUnitLocation}
                  activeUnit={this.state.activeUnit}
                  setActiveUnit={this.setActiveUnit}
                  toggleTrackUnit={this.toggleTrackUnit}
                  trackUnit={this.state.trackUnit}
                  unitsPositions={this.state.unitsPositions}
                  unitsRefs={this.unitsRef}
                  setMapCenter={this.setMapCenter}
                  setMapBounds={this.setMapBounds}
                  updatePoiInMap={this.updatePoiInMap}
                  updatePlaceInMap={this.updatePlaceInMap}
                  callbackSelectedUnit={this.callbackSelectedUnit}
                  setHistoryDateFrom={this.setHistoryDateFrom}
                  setHistoryDateTo={this.setHistoryDateTo}
                  historyFrom={this.state.historyFrom}
                  historyTo={this.state.historyTo}
                  currentLanguage={this.props.currentLanguage}
                  setAddressSearchObject={this.setAddressSearchObject}
                  setAddressCreatePoiCallback={this.createPoiFromAddressSearch}
                  clearAddressSearchObject={this.clearAddressSearchObject}
                  setUnitSearchObject={this.setUnitSearchObject}
                  clearUnitSearchObject={this.clearUnitSearchObject}
                  clearActiveUnit={this.clearActiveUnit}
                  setActiveUnitInformation={this.setActiveUnitInformation}
                  mapHeight={this.state.mapHeightInPx - 5}
                  searchTrackingHistory={this.searchTrackingHistory}
                  clearTrackingHistory={this.clearTrackingHistory}
                />
              }
              sidebarStyle={{
                paddingLeft: "10px",
                width: "25%",
                // overflow: "hidden"
                overflowY: "visible"
                // maxHeight: this.state.mapHeightInPx - 30,
                // height: "100%"
              }}
              {...drawerProps}
              style={{
                overflow: "auto",
                marginTop: "45px",
                height: this.state.mapHeightInPx - 30
              }}
              transitions={true}
            >
              {// Vis enhets historie hvis denne faene er valgt.
              this.state.hideMap === true && (
                <div className="col">
                  {/* <HistoryPanel
                    strings={this.props.strings}
                    innerHeight={this.state.mapHeightInPx - 40}
                    selectedUnits={this.state.selectedUnits}
                    currentLanguage={this.props.currentLanguage}
                    from={this.state.historyFrom}
                    to={this.state.historyTo}
                  /> */}
                </div>
              )}

              {this.state.hideMap !== true && (
                <div
                  className="col"
                  style={{ height: "100%", overflowX: "hidden" }}
                >
                  <Card>
                    <GoogleMapComponent
                      zoom={this.state.zoom}
                      trackUnit={this.state.trackUnit}
                      containerElement={
                        <div style={{ height: `100%`, width: `100%` }} />
                      }
                      mapElement={
                        <div style={{ height: `100%`, width: `100%` }} />
                      }
                      center={this.state.mapCenter}
                      updateCenter={this.updateMapCenter}
                      onZoomChanged={this.zoomChanged}
                      onMapMounted={this.handleMapMounted}
                      heading={this.state.location.heading}
                      onRightClick={this.onMapContextualMenu}
                    >
                      {this.state.activeUnit > 0 &&
                        this.state.showUnitInfo && (
                          <div
                            style={{
                              padding: "5px",
                              paddingLeft: "10px",
                              width: "500px",
                              maxHeight:
                                this.state.mapHeightInPx -
                                MENU_HEIGHT_IN_PX -
                                75,
                              position: "absolute",
                              top: "70px",
                              right: "20px",
                              border: "1px solid rgba(0,0,0,0.2)",
                              borderRadius: "4px",
                              boxShadow: "3px 3px 15px rgba(0,0,0,0.15)",
                              backgroundColor: "rgba(255,255,255,0.85)",
                              overflowY: "auto"
                            }}
                            onClick={() => this.setActiveUnit()}
                          >
                            <ActiveUnit
                              strings={strings}
                              activeUnit={this.state.activeUnit}
                              unit={_.find(this.state.activeUnitInfo, {
                                radioNumber: this.state.activeUnit
                              })}
                              unitPosition={_.find(this.state.unitsPositions, {
                                radioNumber: this.state.activeUnit
                              })}
                              currentLanguage={this.props.currentLanguage}
                              unitOrderInfo={this.state.activeUnitOrderInfo}
                            />
                          </div>
                        )}
                      {this.state.showMapContextualMenu && (
                        <MapContextualMenu
                          isHidden={!this.state.showMapContextualMenu}
                          target={this.state.mapTargetPoint}
                          strings={strings}
                          onDismiss={() =>
                            this.setState({
                              showMapContextualMenu: !this.state
                                .showMapContextualMenu
                            })
                          }
                          createPoiFromMapClick={this.createPoiFromMapClick}
                          setMapCenter={this.setMapCenter}
                          unitsRefs={this.unitsRef}
                          activeUnit={this.state.activeUnit}
                        />
                      )}
                      {this.state.unitsPositions.map((unit, index) => (
                        <VehicleMapObject
                          key={unit.radioNumber}
                          ref={vmo => (this.unitsRef[unit.radioNumber] = vmo)}
                          onUnmount={this.removeRefs}
                          unit={unit}
                          setMapCenter={this.setMapCenter}
                          onClick={this.setActiveUnit}
                          activeUnit={this.state.activeUnit}
                        />
                      ))}
                      {this.state.unitSearchObject &&
                        this.state.unitSearchObject.map((unit, index) => {
                          return (
                            <VehicleMapObject
                              key={index}
                              unit={unit}
                              onClick={this.setActiveUnitFromSearch}
                            />
                          );
                        })}
                      {this.state.addressSearchObject && (
                        <PoiMapObject
                          object={this.state.addressSearchObject}
                          label={this.state.addressSearchObject.label}
                          onLabelClick={this.clearAddressSearchObject}
                          on
                        />
                      )}
                      {this.state.poiInMap.map((poi, index) => (
                        <PoiMapObject key={index} object={poi} />
                      ))}
                      {this.state.placeInMap.map((place, index) => (
                        <PoiMapObject key={place.key} object={place} />
                      ))}
                      {this.state.trackingInfo &&
                        this.state.trackingInfo.length > 0 && (
                          <Tracking
                            trackingArray={this.state.trackingInfo}
                            map={this._map}
                          />
                        )}

                      <MapControl
                        controlPosition={google.maps.ControlPosition.LEFT_TOP}
                        onClick={() => this.toggleDrawer()}
                      >
                        {this.state.open === true
                          ? helpers.Cap(strings.hide)
                          : helpers.Cap(strings.show)}
                        {" " + strings.mapsettings}
                      </MapControl>
                    </GoogleMapComponent>
                  </Card>
                </div>
              )}
            </Drawer>
          </div>
        </div>
      );
    }

    clearAddressSearchObject = () => {
      this.setState({ addressSearchObject: undefined });
    };

    toggleDrawer = () => {
      this.setState({ open: !this.state.open, docked: false });
      this.updateWindowDimensions();
      this.updateMapCenter();
    };

    openDrawer = () => {
      if (!this.state.open) {
        this.setState({ open: true, docked: true });
        this.updateWindowDimensions();
        this.updateMapCenter();
      }
    };

    closeDrawer = () => {
      this.setState({ open: false, docked: true });
      this.updateWindowDimensions();
      this.updateMapCenter();
    };
    // add all selected units to bounds, showing them all inside the map at optimal zoom.

    setMapBounds = () => {
      this._map.fitBounds(this.state.mapBounds);
    };

    updateMapCenter = () => {
      let c = this._map.getCenter();
      this.setState({ mapCenter: { lat: c.lat(), lng: c.lng() } });
    };

    setMapCenter = (unit, zoom) => {
      let pos = _.find(this.state.unitsPositions, { radioNumber: unit });
      let newZoom = zoom && zoom !== this.state.zoom ? zoom : this.state.zoom;
      if (pos !== undefined) {
        let bounds = new google.maps.LatLngBounds();
        bounds.extend({
          lat: parseFloat(pos.latitude),
          lng: parseFloat(pos.longitude)
        });
        // this._map.panToBounds(bounds);
        // this.mapCenter = {lat: parseFloat(pos.latitude), lng: parseFloat(pos.longitude) };
        // this._map.setBounds(bounds);
        this.setState({
          zoom: newZoom,
          mapCenter: {
            lat: parseFloat(pos.latitude),
            lng: parseFloat(pos.longitude)
          }
        });
      }
    };

    setAddressSearchObject = object => {
      this.setState({
        addressSearchObject: object,
        mapCenter: {
          lat: parseFloat(object.latitude),
          lng: parseFloat(object.longitude)
        }
      });
    };

    setActiveUnit = id => {
      let newActiveUnitId = id;
      if (this.state.activeUnit === newActiveUnitId) {
        newActiveUnitId = 0;

        this.setState((state, props) => {
          return { activeUnit: newActiveUnitId };
        });
      } else {
        if (newActiveUnitId) {
          this.loadActiveUnitInformation(
            newActiveUnitId,
            activeUnitOrderInfo => {
              this.setState((state, props) => {
                return {
                  activeUnit: newActiveUnitId,
                  activeUnitOrderInfo,
                  trackUnit: false,
                  showUnitInfo: true
                };
              });
            }
          );
        }
      }
    };

    clearActiveUnit = () => {
      this.setState({ activeUnit: -1 });
    };

    unsetActiveUnit = () => {
      this.setState({ activeUnit: -1 });
    };

    loadActiveUnitInformation = (unitId, callback) => {
      return fetch(`${API}/api/v1.0/Units/Get/VehicleInfo/${unitId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "bearer " + localStorage.getItem("access_token")
        }
      })
        .then(response => {
          if (response.ok) {
            // Request success
            response.json().then(orderInfo => {
              // Bygger opp listen til å passe med DropDown kravene.

              const activeUnitOrderInfo =
                orderInfo.length > 0 ? orderInfo[0] : undefined;

              if (callback) {
                callback(activeUnitOrderInfo);
              } else {
                this.setState({ activeUnitOrderInfo });
              }
            });
          } else {
            // Request failed
            response.text().then(result => {
              helpers.notifyError(
                this.props.strings.errorMessageNoCoordinatesFound(unitId)
              );
            });
          }
        })
        .catch(error => {});
    };

    setActiveUnitFromSearch = () => {
      let newActiveUnitId =
        this.state.unitSearchObject &&
        this.state.unitSearchObject[0].radioNumber;

      if (this.state.activeUnit === newActiveUnitId) {
        newActiveUnitId = 0;
      }
      this.setState((state, props) => {
        return { trackUnit: false };
      });

      // this.setMapCenter(newActiveUnitId);

      this.setState((state, props) => {
        return { activeUnit: newActiveUnitId };
      });
    };

    toggleTrackUnit = () => {
      this.setState({ trackUnit: !this.state.trackUnit });
    };

    callbackSelectedUnit = store => {
      this.setState((state, props) => {
        return { selectedUnits: store };
      });
    };

    updatePoiInMap = poiObject => {
      let store = this.state.poiInMap;
      let index = _.indexOf(store, poiObject);
      if (index >= 0) {
        _.pullAt(store, index);
        if (store.length === 0) {
          store = [];
        }
      } else {
        store.push(poiObject);
      }
      this.setState({ poiInMap: store });
    };

    updatePlaceInMap = place => {
      let store = this.state.placeInMap;
      let index = _.indexOf(store, place);
      if (index >= 0) {
        _.pullAt(store, index);
        if (store.length === 0) {
          store = [];
        }
      } else {
        store.push(place);
      }
      this.setState({ placeInMap: store });
    };

    updateUnitLocation = units => {
      // Load Groups
      //TODO: Remove this
      let bounds = new google.maps.LatLngBounds();
      if (units.length > 0) {
        return fetch(
          API + "/api/v1.0/Units/Get/VehiclesCoordinates/" + units.slice(),
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "bearer " + localStorage.getItem("access_token")
            }
          }
        )
          .then(response => {
            if (response.ok) {
              // Request success
              response.json().then(result => {
                // Inkludere alle punkter i bounds, for å kartet automatisk viser alle punkter.
                if (result.length > 0) {
                  result.map((key, index) => {
                    let pos = {
                      lat: parseFloat(key.latitude),
                      lng: parseFloat(key.longitude)
                    };
                    return bounds.extend(pos);
                  });

                  // Oppdatere posisjoner.
                  if (bounds !== null && this._map) {
                    this._map.fitBounds(bounds);
                  }

                  if (this.state.trackUnit) {
                    this.setMapCenter(this.state.activeUnit);
                  }

                  this.setState({ unitsPositions: result, mapBounds: bounds });
                }
              });
            } else if (response.status === 400) {
              response.text().then(result => {
                helpers.notifyGenericError(this.props.strings, result);
                console.warn(`updateUnitLocation: ${result}`);
              });
            } else {
              // Request failed
              response.text().then(result => {
                helpers.notifyGenericError(this.props.strings, result);
              });
            }
          })
          .catch(error => {});
      } else {
        this.setState({
          unitsPositions: [],
          zoom: 6,
          location: {
            latitudeDeg: 59.3558828,
            longitudeDeg: 17.972331
          }
        });
      }
    };

    setUnitSearchObject = units => {
      // Load Groups
      //TODO: Remove this
      let bounds = new google.maps.LatLngBounds();
      if (units.length > 0) {
        return fetch(
          API + "/api/v1.0/Units/Get/VehiclesCoordinates/" + units.slice(),
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "bearer " + localStorage.getItem("access_token")
            }
          }
        )
          .then(response => {
            if (response.ok) {
              // Request success
              response.json().then(result => {
                // Inkludere alle punkter i bounds, for å kartet automatisk viser alle punkter.
                if (result.length > 0) {
                  result.forEach((key, index) => {
                    let pos = {
                      lat: parseFloat(key.latitude),
                      lng: parseFloat(key.longitude)
                    };
                    bounds.extend(pos);
                  });

                  this._map.fitBounds(bounds);
                  this.setState({
                    unitSearchObject: result
                  });
                } else {
                  helpers.notifyWarning(
                    this.props.strings.errorMessageNoCoordinatesFound(
                      units.slice()
                    )
                  );
                }
              });
            } else if (response.status === 400) {
              response.text().then(result => {
                console.error(
                  `setUnitSearchObject: (${response.status}) ${result}`
                );
              });
            } else {
              // Request failed
              response.text().then(result => {
                console.error(
                  `setUnitSearchObject: (${response.status}) ${result}`
                );
              });
            }
          })
          .catch(error => {
            console.error(`setUnitSearchObject: ${error}`);
          });
      } else {
        this.setState({
          unitsPositions: [],
          location: {
            latitudeDeg: 59.3558828,
            longitudeDeg: 17.972331
          }
        });
      }
    };

    clearUnitSearchObject = () => {
      this.setState({
        unitSearchObject: undefined
      });
    };

    updateWindowDimensions = () => {
      this.setState({
        mapHeightInPx: window.innerHeight - MENU_HEIGHT_IN_PX - 25
      });
    };

    handleMapMounted = map => {
      this._map = map;

      if (this._map !== null) {
        this._map
          .getDiv()
          .addEventListener("mouseup", e => this.setMapPixelCoordinates(e));
      }
    };

    zoomChanged = value => {
      const nextZoom = this._map.getZoom();
      this.setState({ zoom: nextZoom });
    };

    showInfo = () => {
      this.setState({ showInfo: !this.state.showInfo });
    };

    setHistoryDateFrom = from => {
      this.setState((state, props) => {
        return { historyFrom: from };
      });
    };

    setHistoryDateTo = to => {
      this.setState((state, props) => {
        return { historyTo: to };
      });
    };

    onOpenChange = open => {
      this.setState({ open });
    };
    onDock = () => {
      const docked = !this.state.docked;
      this.setState({
        docked
      });
      if (!docked) {
        this.onOpenChange(false);
      }
    };

    setMapPixelCoordinates = e => {
      e.preventDefault();
      if (e.button === 2) {
        this.setState({
          mapTargetPoint: { x: e.pageX + 5, y: e.pageY + 5 }
        });
      }
    };

    setActiveUnitInformation = activeUnitInfo => {
      this.setState({ activeUnitInfo });
    };

    onMapContextualMenu = e => {
      this.setState({
        showMapContextualMenu: true,
        clickedLatLong: e.latLng
      });
    };

    onNewPoiFormDismiss = () => {
      this.setState({ showNewPoiForm: !this.state.showNewPoiForm });
    };

    createPoiFromMapClick = () => {
      let lat = this.state.clickedLatLong.lat();
      let lng = this.state.clickedLatLong.lng();
      this.setState({ showNewPoiForm: true, newPoi: { lat: lat, lng: lng } });
    };

    createPoiFromAddressSearch = (lat, lng) => {
      this.setState({ showNewPoiForm: true, newPoi: { lat: lat, lng: lng } });
    };

    // Remove reference to object on umount
    removeRefs = vehicle => {
      let index = this.unitsRef.indexOf(vehicle);
      this.unitsRef = _.pullAt(this.unitsRef, index);
    };

    onHelpDismiss = () => {
      this.setState({ showHelpScreen: false });
    };

    setActiveMenuItem = menuItemString => {
      const { menuItem } = this.state;
      let dockerState = this.state.open;

      if (this.state.open && menuItem === menuItemString) {
        dockerState = !dockerState;
      } else {
        dockerState = true;
      }
      this.setState({
        trackingInfo: [],
        // zoom: 8,
        // unitsPositions: [],
        // activeUnit: null,
        menuItem: menuItemString,
        open: dockerState,
        docked: dockerState
      });
    };

    keyDownBinding = e => {
      if (e.keyCode === 27 && this.state.activeUnit) {
        this.setState({ showUnitInfo: false });
        e.preventDefault();
      }

      if (e.keyCode === 112) {
        this.setActiveMenuItem("tdx");
        e.preventDefault();
      }

      if (e.keyCode === 113) {
        this.setActiveMenuItem("groups");
        e.preventDefault();
      }

      if (e.keyCode === 114) {
        this.setActiveMenuItem("poi");
        e.preventDefault();
      }

      if (e.keyCode === 115) {
        this.setActiveMenuItem("search");
        e.preventDefault();
      }

      if (e.keyCode === 116) {
        this.setActiveMenuItem("tracking");
        e.preventDefault();
      }

      if (e.keyCode === 117) {
        this.setActiveMenuItem("history");
        e.preventDefault();
      }
      // if (e.keyCode === 113 && this.state.activeUnit) {
      //   this.setState({ showUnitInfo: !this.state.showUnitInfo });
      //   e.preventDefault();
      // }
    };

    notifyError = error => {
      toast.error(error, {
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    };

    clearTrackingHistory = () => {
      this.setState({ trackingInfo: [], zoom: 6 });
    };
    searchTrackingHistory = (type, trackingId, interval, fromdate, todate) => {
      fetch(
        `${API}/api/v1.0/Coordinates/Get/History/${type}/${trackingId}/${interval}/${fromdate}/${todate}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "bearer " + localStorage.getItem("access_token")
          }
        }
      )
        .then(response => {
          if (response.ok) {
            // Request success
            response.json().then(trackingInfo => {
              this.setState({ trackingInfo });
            });
          } else {
            if (response.status === 400) {
              helpers.notifyWarning(
                this.props.strings.errorMessageNoTrackingFound(
                  type,
                  trackingId,
                  interval,
                  fromdate,
                  todate
                )
              );
            } else {
              // Request failed
              response.text().then(result => {
                helpers.notifyError(result);
              });
            }
          }
        })
        .catch(error => {});
    };

    registerClearForm = callback => {
      this.setState({ clearMapContentPicker: callback });
    };

    clearForm = () => {
      if (this.state.clearMapContentPicker) {
        this.state.clearMapContentPicker();
      }
      this.clearActiveUnit();
      this.clearUnitSearchObject();
      this.setState({
        poiInMap: [],
        placeInMap: [],
        unitsPositions: [],
        zoom: 6,
        location: {
          latitudeDeg: 59.3558828,
          longitudeDeg: 17.972331
        }
      });
    };
  }
);

// export default withRouter(connect(mapStateToProps, mapDispatchToProps)(translate()(MapContainer)));
