// import _ from "lodash";
// Reducer
const AppReducers = (state = {}, action) => {
  switch (action.type) {
    case "IS_LOADING":
      return { ...state, isLoading: action.isLoading };
    case "TOGGLE_USERSETTINGS":
      return { ...state, showUserSettings: action.showUserSettings };
    case "TOGGLE_USERNOTIFICATION":
      return { ...state, showUserNotifications: action.showUserNotifications };
    case "ADD_USERNOTIFICATION":
      return {
        ...state,
        notifications: [
          ...state.notifications,
          { message: action.message, logtime: action.logtime }
        ]
      };
    case "SET_LANGUAGE":
      return { ...state, currentLanguage: action.currentLanguage };

    case "POI_LOAD_USERPOI":
      return {
        ...state,
        poi: {
          ...state.poi,
          userPois: action.userPois,
          pois: action.pois
        }
      };

    case "POI_LOAD_PLACES":
      return {
        ...state,
        poi: {
          ...state.poi,
          userPlaces: action.userPlaces,
          places: action.places
        }
      };
    case "POI_UPDATE_SELECTED_POI": {
      return {
        ...state,
        poi: {
          ...state.poi,
          selectedPOIs: action.array
        }
      };
    }

    case "POI_UPDATE_SELECTED_PLACE": {
      return {
        ...state,
        poi: {
          ...state.poi,
          selectedPlaces: action.array
        }
      };
    }

    case "USERADM_SHOWDIALOG": {
      return {
        ...state,
        userAdmin: {
          ...state.userAdmin,
          isActive: action.visible
        }
      };
    }

    default:
      return state;
  }
};

// Mapping

export default AppReducers;
