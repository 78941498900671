import * as React from "react";
import Flexbox from "flexbox-react";
import translate from "../../i18n/Translate";
// import MenuItem from "./MenuItem";
import MenuUserItem from "./MenuUserItem";
import { MENU_HEIGHT_IN_PX } from "utils/constants";

import "./menu.css";

class Menu extends React.Component {
  redirect = url => {
    this.props.history.push(url, undefined);
    this.props.history.go(1);
  };

  render() {
    const layout = this.props.application.layout;
    let userType = parseInt(this.props.authentication.userInfo.userType, 10);
    return (
      <div>
        <Flexbox
          element="header"
          className={
            "menubar ms-fontColor-white ms-bgColor-" + layout.primaryColor
          }
        >
          <Flexbox className="center-vertical-aligned ms-font-xl menuHeaderText">
            <div onClick={this.props.onLogoClick}>
              <img
                src="logo.png"
                width="122px"
                height={MENU_HEIGHT_IN_PX}
                alt=""
              />
            </div>
          </Flexbox>

          <Flexbox
            marginLeft="48px"
            className={"ms-bgColor-" + layout.primaryColor}
          >
            {/* 
            TODO: Add this as .map with props 
            TODO: Add translated text
            */}
            {/* 
            <MenuItem {...this.props} url="/">
              Map
            </MenuItem>
            <MenuItem {...this.props} url="/useradmin">
              {this.props.strings.useradministration}
            </MenuItem> */}
          </Flexbox>

          <Flexbox
            marginLeft="auto"
            className={"ms-bgColor-" + layout.primaryColor}
          >
            <Flexbox
              marginLeft="auto"
              className={"ms-fontColor-white ms-bgColor-" + layout.primaryColor}
            >
              {userType &&
                userType > 1 && (
                  <div style={{ marginLeft: 5, marginRight: 5 }}>
                    <div
                      className={`ms-bgColor-${layout.primaryColor}--hover`}
                      onClick={() => this.props.toggleUserAdministration(true)}
                    >
                      <i
                        className="menu-center-vertical-aligned ms-font-xl ms-Icon ms-Icon--People"
                        style={{ marginLeft: "10px", marginRight: "10px" }}
                        aria-hidden="true"
                      />
                    </div>
                  </div>
                )}
              <MenuUserItem
                user={this.props.authentication.userInfo}
                isAuthenticated={this.props.authentication.isAuthenticated}
                setLanguage={this.props.setLanguage}
                onLogout={this.props.logoutUser}
                onLanguageChange={this.props.setLanguage}
              />
            </Flexbox>
          </Flexbox>
        </Flexbox>
      </div>
    );
  }
}

export default translate("Menu")(Menu);
