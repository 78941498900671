export default {
  activeUnit: "active unit",
  address: "address",
  and: " and ",
  centerMap: "center map",
  companies: "companies",
  company: "company",
  customer: "customer",
  date: "date",
  distanceInKm: "Distance in Km",
  driver: "driver",
  emailLabel: "Email",
  endTime: "End time",
  followUnit: "Follow unit",
  from: "from",
  groups: "groups",
  hide: "hide",
  history: "history",
  locate: "locate",
  locateInMap: "Locate in map",
  login: "Login",
  loginExpired: "Your session has expired, please logon with your provided username and password.",
  loginHeaderText: "Login",
  loginInProgress: "Logging in",
  logout: "Logoff",
  map: "map",
  mapsettings: "map settings",
  noUnitsApplicable: "No units available",
  notresponsiveheader: "Notice",
  notresponsivetext: "This page is not created for mobile devices.",
  office: "office",
  offices: "offices",
  order: "order",
  orderInformation: "Order information",
  orderStatusTimeStamp: "Status timestamp",
  passwordLabel: "Password",
  poi: "poi",
  posAge: "Position age",
  posInformation: "Position information",
  search: "search",
  select: "selecet",
  selectAll: "Select all",
  selectDate: "Select a date",
  selectCompany: "Select company",
  selectLanguage: "Select language",
  selectNone: "Select none",
  selectOfficeOrGroup: "Select office or groups",
  selectUnits: "Select unit",
  selection: "selection",
  setSelectedUnit: "Set as selected unit",
  show: "show",
  showAllUnitsInMap: "Show all units on map",
  showInMap: "Show in map",
  speed: "speed",
  startTime: "Start time",
  status: "status",
  tdx: "TDX",
  to: "to",
  tracking: "tracking",
  unit: "unit",
  unitCategory: "unit category",
  unitHistory: "unit history",
  unitInformation: "unit information",
  units: "units",
  userSettingsHeaderText: "User settings",
  usernameLabel: "Username",
  version: "Version",
  zoomToUnit: "Zoom to unit"
};
