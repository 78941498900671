import React, { Component } from "react";
import _ from "lodash";
import { DropdownMenuItemType } from "office-ui-fabric-react/lib/Dropdown";
import { withRouter } from "react-router-dom";
import { API } from "utils/api";
import translate from "i18n/Translate";
import { connect } from "react-redux";
import { mapDispatchToProps } from "actions/mapDispatchToProps";
import * as helpers from "utils/functions";
import { TDX, Groups, POI, Search, Tracking, History } from "./Content/";
import UnitList from "components/Map/UnitList/UnitList";
import NewGroupForm from "components/NewGroupForm/NewGroupForm";

const mapStateToProps = state => {
  return state;
};

class MapContentPicker extends Component {
  constructor(props) {
    super(props);

    this.initialState = {
      groupItems: [],
      unitItems: [],
      unitInformation: [],
      selectedGroups: [],
      selectedUnits: [],
      userGroups: [],
      userGroupsOptions: [],
      selectedUserGroupsKeys: [],
      showNewGroupForm: false
    };

    this.state = this.initialState;
  }

  componentDidMount = () => {
    // load data
    this.loadUserGroups();
    this.loadTDXGroups(this.props);
    let intervalId = setInterval(() => {
      if (this.state.selectedUnits.length > 0) {
        this.props.updateUnitLocation(this.state.selectedUnits);
      }
    }, 60000);

    this.setState((state, props) => {
      return { intervalId: intervalId };
    });
  };

  componentWillUnmount = () => {
    clearInterval(this.state.intervalId);
  };

  componentDidUpdate = oldProps => {
    this.props.registerClearForm(() => {
      this.props.updateSelectedPOI([]);
      this.props.updateSelectedPlace([]);
      this.props.clearAddressSearchObject();
      this.props.clearUnitSearchObject();
      this.clearSelectedGroups();
      this.clearSelectedUnits();
      this.clearUnitList();
      this.setState({ selectedUserGroupsKeys: [] });
    });

    if (
      oldProps.activeMenu !== this.props.activeMenu &&
      this.props.activeMenu === "tracking"
    ) {
      this.clearSelectedGroups();
      this.clearSelectedUnits();
    }
  };

  componentWillUnmount = () => {
    this.props.registerClearForm(undefined);
  };

  render() {
    const { strings } = this.props;
    let showActiveUnits = true;
    let menuItem = undefined;
    switch (this.props.activeMenu) {
      case "groups":
        menuItem = (
          <Groups
            strings={strings}
            selectedUserGroupsKeys={this.state.selectedUserGroupsKeys}
            setSelectedUserGroupKeys={this.setSelectedUserGroupKeys}
            addUnitsToList={this.addUnitsToList}
            removeUnitsFromList={this.removeUnitsFromList}
            userGroupsOptions={this.state.userGroupsOptions}
            userGroups={this.state.userGroups}
            loadUserGroups={this.loadUserGroups}
            clearUnitList={this.clearUnitList}
          />
        );
        break;

      case "poi":
        menuItem = (
          <POI
            strings={strings}
            updatePoiInMap={this.props.updatePoiInMap}
            updatePlaceInMap={this.props.updatePlaceInMap}
          />
        );
        break;

      case "search":
        menuItem = (
          <Search
            strings={strings}
            mapRef={this.props.mapRef}
            setAddressSearchObject={this.props.setAddressSearchObject}
            setAddressCreatePoiCallback={this.props.setAddressCreatePoiCallback}
            clearAddressSearchObject={this.props.clearAddressSearchObject}
            updateUnitLocation={this.props.setUnitSearchObject}
            clearUnitLocation={this.props.clearUnitSearchObject}
          />
        );
        showActiveUnits = false;
        break;

      case "tracking":
        menuItem = (
          <Tracking
            strings={strings}
            searchTrackingHistory={this.props.searchTrackingHistory}
            clearTrackingHistory={this.props.clearTrackingHistory}
          />
        );
        break;

      case "history":
        menuItem = (
          <History
            strings={strings}
            setHistoryDateFrom={this.props.setHistoryDateFrom}
            setHistoryDateTo={this.props.setHistoryDateTo}
            historyFrom={this.props.historyFrom}
            historyTo={this.props.historyTo}
            currentLanguage={this.props.currentLanguage}
          />
        );
        break;

      case "tdx":
      default:
        menuItem = (
          <TDX
            strings={strings}
            updateSelectedGroups={this.updateSelectedGroups}
            clearSelectedGroups={this.clearSelectedGroups}
            selectedGroups={this.state.selectedGroups}
            groupItems={this.state.groupItems}
            updateSelectedUnits={this.updateSelectedUnits}
            clearSelectedUnits={this.clearSelectedUnits}
            selectedUnits={this.state.selectedUnits}
            setActiveUnit={this.props.setActiveUnit}
            activeUnit={this.props.activeUnit}
            unitItems={this.state.unitItems}
            currentLanguage={this.props.currentLanguage}
          />
        );
        break;
    }

    return (
      <div>
        <NewGroupForm
          strings={strings}
          isOpen={this.state.showNewGroupForm}
          onDismiss={this.newGroupFormDismiss}
          selectedUnits={this.state.selectedUnits}
          unitItems={this.state.unitItems}
          loadUserGroups={this.loadUserGroups}
        />
        <div>{menuItem}</div>
        {this.state.unitItems.length > 0 &&
        showActiveUnits &&
        (this.props.activeMenu === "tdx" ||
          this.props.activeMenu === "groups") ? (
          <UnitList
            units={this.state.unitItems}
            strings={strings}
            updateSelectedUnits={this.updateSelectedUnits}
            selectedUnits={this.state.selectedUnits}
            setActiveUnit={this.props.setActiveUnit}
            setSelectedUnits={this.setSelectedUnits}
            toggleTrackUnit={this.props.toggleTrackUnit}
            trackUnit={this.props.trackUnit}
            activeUnit={this.props.activeUnit}
            unitsRefs={this.props.unitsRefs}
            setMapCenter={this.props.setMapCenter}
            setMapBounds={this.props.setMapBounds}
            currentLanguage={this.props.currentLanguage}
            newGroup={this.newGroup}
            mapHeight={this.props.mapHeight}
            clearUnitLocation={this.props.clearUnitSearchObject}
            clearAddressSearchObject={this.props.clearAddressSearchObject}
          />
        ) : null}

        {/* {activeUnit && (
          <ActiveUnit
            strings={strings}
            unit={activeUnit}
            unitPosition={_.find(this.props.unitsPositions, {
              radioNumber: activeUnit.radioNumber
            })}
            currentLanguage={this.props.currentLanguage}
          />
        )} */}
      </div>
    );
  }

  createBounds = () => {};

  copyArray(array) {
    let newArray = [];
    for (let i = 0; i < array.length; i++) {
      newArray[i] = array[i];
    }
    return newArray;
  }

  setSelectedUserGroupKeys = selectedUserGroupsKeys => {
    this.setState({ selectedUserGroupsKeys, selectedGroups: [] });
  };

  updateSelectedGroups = item => {
    let store =
      this.state.selectedGroups !== undefined
        ? this.copyArray(this.state.selectedGroups)
        : [];
    let index = _.indexOf(store, item.key);
    if (item.selected) {
      store.push(item.key);
    } else {
      store.splice(index, 1);
      // _.pullAt(store, index);
    }
    //TODO: Handtering ved store.length == 0
    this.loadTDXUnits(this.props, store);
    this.setState({ selectedGroups: store, selectedUserGroupsKeys: [] });
  };

  updateSelectedUnits = key => {
    let store = this.state.selectedUnits;
    let index = _.indexOf(store, key);
    if (index >= 0) {
      _.pullAt(store, index);
      if (this.props.activeUnit === key) {
        this.props.clearActiveUnit();
      }

      if (store.length === 0) {
        store = [];
      }
    } else {
      store.push(key);
    }
    this.props.updateUnitLocation(store);
    this.props.callbackSelectedUnit(store);
    this.setState({ selectedUnits: store });
  };

  setSelectedUnits = arrayUnits => {
    let store = [];
    if (arrayUnits.length > 0) {
      arrayUnits.map((item, index) => {
        store.push(item.radioNumber);
        return store;
      });
    } else {
      this.props.setActiveUnit(0);
    }
    this.props.updateUnitLocation(store);
    this.setState({ selectedUnits: store });
  };

  getSelectedUnits = () => {
    // TODO: Denne bør flyttes opp et hakk i hierarkiet.
    return this.state.selectedUnits;
  };

  clearSelectedGroups = () => {
    this.loadTDXUnits(this.props, []);
    this.setState({ selectedGroups: [], selectedUnits: [] });
  };

  clearSelectedUnits = () => {
    this.setState({ selectedUnits: [] });
  };

  // Load functions

  loadUserGroups = () => {
    fetch(API + `/api/v1.0/User/Get/Groups`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("access_token")
      }
    })
      .then(response => {
        if (response.ok) {
          // Request success
          response.json().then(userGroups => {
            const userGroupsOptions = [];
            userGroups.forEach((item, key) => {
              userGroupsOptions.push({
                key: item.enhetsGruppPk,
                text: item.enhetsGruppNamn
              });
            });
            this.setState({ userGroups, userGroupsOptions });
          });
        } else if (response.status === 400) {
          // console.error(`loadUserGroups: (${response.status}) No groups found`);
        } else {
          response.text().then(result => {
            // console.error(`loadUserGroups: (${response.status}) ${result}`);
          });
        }
      })
      .catch(error => {
        helpers.notifyNetworkError(
          this.props.strings,
          this.props.strings.groups
        );
      });
  };

  loadTDXGroups = props => {
    // Load Groups
    //TODO: Remove this
    let groupList = [];
    return fetch(API + "/api/v1.0/Units/Get/VehicleGroupsAndOffices", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("access_token")
      }
    })
      .then(response => {
        if (response.ok) {
          // Request success
          response.json().then(result => {
            // Bygger opp listen til å passe med DropDown kravene.

            let lastEntryType = "";
            _.forEach(_.sortBy(result, ["entryType"]), function(object) {
              if (object.entryType !== lastEntryType) {
                // legger inn Header verdi
                let headerText = object.entryType; // Default språk fra API
                switch (object.entryType) {
                  case "Office":
                    headerText = helpers.Cap(props.strings.offices);
                    break;
                  default:
                    headerText = helpers.Cap(props.strings.groups);
                    break;
                }

                groupList.push({
                  key: headerText,
                  text: headerText,
                  itemType: DropdownMenuItemType.Header
                });
              }
              groupList.push({ key: object.entryCode, text: object.entryText });
              lastEntryType = object.entryType;
            });

            this.setState({ groupItems: groupList });
          });
        } else {
          // Request failed
          response.text().then(result => {
            helpers.notifyGenericError(this.props.strings, result);
          });
        }
      })
      .catch(error => {
        helpers.notifyNetworkError(
          this.props.strings,
          this.props.strings.offices
        );
      });
  };

  loadTDXUnits = (props, groups) => {
    // Load Groups
    //TODO: Remove default LABB
    if (groups.length > 0) {
      return fetch(API + "/api/v1.0/Units/Get/UserVehicles/" + groups.slice(), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "bearer " + localStorage.getItem("access_token")
        }
      })
        .then(response => {
          if (response.ok) {
            // Request success
            response.json().then(result => {
              // Bygger opp listen til å passe med DropDown kravene.
              let unitList = _.sortBy(result, ["radioNumber"]);

              //TODO: Add only new items not in array from before.
              this.setState({
                unitItems: unitList,
                unitInformation: result
              });

              this.props.setActiveUnitInformation(result);
            });
          } else {
            // Request failed
            response.text().then(result => {
              helpers.notifyGenericError(this.props.strings, result);
            });
          }
        })
        .catch(error => {
          helpers.notifyNetworkError(
            this.props.strings,
            this.props.strings.units
          );
        });
    } else {
      this.setState({ unitItems: [] });
    }
  };

  setSelectedGroups = () => {
    const selectedGroups = [];
    this.setState({ selectedGroups });
  };

  addUnitsToList = unitIds => {
    let unitItems = this.copyArray(this.state.unitItems);
    unitIds.forEach((unit, uid) => {
      unit.enhetEnhetsGrupps.forEach((vehicle, key) => {
        // hvis den ikke finnes i lista fra før, så legger vi den til
        if (
          _.findIndex(unitItems, o => {
            return o.radioNumber === vehicle.radioNumber;
          }) === -1
        ) {
          unitItems.push(vehicle);
        }
      });
    });
    unitItems = _.sortBy(unitItems, o => {
      return o.radioNumber;
    });
    this.setState({ unitItems });
  };

  removeUnitsFromList = unitIds => {
    let unitItems = this.copyArray(this.state.unitItems);
    unitIds &&
      unitIds.forEach((unit, uid) => {
        unit.enhetEnhetsGrupps.forEach((vehicle, key) => {
          _.remove(unitItems, i => {
            return i.radioNumber === vehicle.radioNumber;
          });
        });
      });
    this.setState({ unitItems });
  };

  updateUnitsList = unitIds => {
    let unitItems = this.copyArray(this.state.unitItems);

    unitIds.forEach((unit, uid) => {
      unit.enhetEnhetsGrupps.forEach((vehicle, key) => {
        // hvis den ikke finnes i lista fra før, så legger vi den til
        if (
          _.findIndex(unitItems, o => {
            return o.radioNumber === vehicle.radioNumber;
          }) === -1
        ) {
          unitItems.push(vehicle);
        }
      });
    });
    this.setState({ unitItems });
  };

  clearUnitList = () => {
    this.setState({ unitItems: [] });
  };

  newGroup = () => {
    this.setState({ showNewGroupForm: true });
  };

  newGroupFormDismiss = () => {
    this.setState({ showNewGroupForm: false });
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(translate()(MapContentPicker))
);
