import * as LayoutAction from "actions/LayoutActions";
import * as AuthenticationActions from "actions/AuthenticationActions";
// import * as LogActions from "actions/LogActions";
import * as PoiActions from "actions/PoiActions";
import * as UserAdministration from "actions/UserAdministration";

export const mapDispatchToProps = dispatch => {
  return {
    toggleLoading: loading => {
      dispatch(LayoutAction.isLoading(loading));
    },

    toggleUserSettings: state => {
      dispatch(LayoutAction.toggleUserSettings(state));
    },

    toggleUserAdministration: active => {
      dispatch(UserAdministration.USERADM_SHOWDIALOG(active));
    },

    // toggleUserNotification: state => {
    //   dispatch(LayoutAction.toggleUserNotification(state));
    // },

    // addUserNotification: message => {
    //   dispatch(LayoutAction.addNotification(message));
    // },

    setLanguage: lang => {
      dispatch(LayoutAction.setLanguage(lang));
    },

    preLoginUser: (username, password) => {
      AuthenticationActions.preLoginUser(username, password, dispatch);
    },

    loginUser: (username, password, company) => {
      AuthenticationActions.loginUser(username, password, company, dispatch);
    },

    logoutUser: () => {
      AuthenticationActions.logoutUser(dispatch);
    },

    validateUser: () => {
      AuthenticationActions.validateUser(dispatch);
    },

    updateSelectedPOI: key => {
      PoiActions.updateSelectedPOI(dispatch, key);
    },

    loadUserPOIs: (strings, callback) => {
      PoiActions.loadUserPOIs(dispatch, strings, callback);
    },

    updateSelectedPlace: key => {
      PoiActions.updateSelectedPlace(dispatch, key);
    },

    loadUserPlaces: strings => {
      PoiActions.loadUserPlaces(dispatch, strings);
    }
  };
};
