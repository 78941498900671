/* global google */
import React, { Component } from "react";
import { Marker, OverlayView } from "react-google-maps";
import "./VehicleMapObject.css";
import UnitContextualMenu from "components/ContextualMenu/Unit/UnitContextualMenu";
/*

[
  {
    "timeStamp": "2016-04-06T09:57:00",
    "longitude": "17.9723178",
    "latitude": "59.3562355",
    "bearing": "N",
    "bearingDegree": "0",
    "speed": "0",
    "driver": "",
    "nrOfStatus24Orders": 1,
    "radioNumber": 907
  }
]

*/

export default class VehicleMapObject extends Component {
  constructor(props) {
    super(props);
    this.marker = null;
    this.state = {
      isActive: false,
      animation: 0,
      showContextualMenu: false,
      contextualMenuTarget: undefined,
      targetPoint: { x: 0, y: 0 }
    };
  }

  // shouldComponentUpdate = (nextProps, nextState) => {
  //   if (nextProps.activeUnit !== this.props.activeUnit) {
  //     return false;
  //   }
  //   return true;
  // };

  componentWillReceiveProps = nextProps => {
    if (
      nextProps.activeUnit === this.props.unit.radioNumber &&
      nextProps.activeUnit !== this.props.activeUnit
    ) {
      this.locateUnit();
      this.props.setMapCenter(this.props.unit.radioNumber, 18);
    }
  };

  componentWillUnmount = () => {
    this.props.onUnmount && this.props.onUnmount(this.props.unit.radioNumber);
  };

  render() {
    const position = {
      lat: parseFloat(this.props.unit.latitude),
      lng: parseFloat(this.props.unit.longitude)
    };
    const { unit } = this.props;
    let labelCss = undefined;
    let unitColor = undefined;

    if (unit.nrOfStatus24Orders > 0) {
      if (unit.driver.length > 0) {
        labelCss = "vehicellabelloggedinworking";
        unitColor = "green";
      } else {
        labelCss = "vehicellabelloggedoutworking";
        unitColor = "green";
      }
    } else {
      if (unit.driver.length > 0) {
        labelCss = "vehicellabelloggedinnotworking";
      } else {
        labelCss = "vehicellabelloggedoutnotworking";
      }
    }

    if (this.isActiveUnit()) {
      unitColor = "blue";
    }

    return (
      <div onContextMenu={e => this.onContextualMenu(e)}>
        <UnitContextualMenu
          isHidden={!this.state.showContextualMenu}
          target={this.state.contextualMenuTarget}
          onDismiss={() =>
            this.setState({
              showContextualMenu: !this.state.showContextualMenu
            })
          }
        />

        <div onContextMenu={e => this.onContextualMenu(e)}>
          <Marker
            ref={marker => {
              this.marker = marker;
            }}
            onRightClick={e => this.onContextualMenu(e)}
            icon={{
              path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
              fillColor: unitColor,
              fillOpacity: 1,
              strokeWeight: 2,
              strokeColor: "black",
              labelOrigin: new google.maps.Point(-6, 7),
              anchor: new google.maps.Point(0, 2),
              scale: 4,
              rotation: parseInt(unit.bearingDegree, 10)
            }}
            title={unit.driver}
            animation={this.state.animation}
            position={position}
            onClick={() => this.onLabelClick()}
          >
            <OverlayView
              position={position}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              getPixelPositionOffset={(width, height) => ({ x: 10, y: -25 })}
            >
              <div
                className={"clickable " + labelCss}
                onClick={() => this.onLabelClick()}
              >
                {unit.radioNumber}
              </div>
            </OverlayView>
          </Marker>
        </div>
      </div>
    );
  }

  isActiveUnit = () => {
    if (this.props.activeUnit === this.props.unit.radioNumber) {
      return true;
    }
    return false;
  };

  onContextualMenu = e => {
    this.setState({
      showContextualMenu: !this.state.showContextualMenu,
      contextualMenuTarget: e.va,
      targetPoint: { x: e.pageX, y: e.pageY }
    });
  };

  onLabelClick = () => {
    this.props.onClick && this.props.onClick(this.props.unit.radioNumber);
  };

  locateUnit = () => {
    let animation = this.marker.getAnimation();
    if (animation === 6 || animation === 0 || animation === null) {
      animation = google.maps.Animation.BOUNCE;
    } else {
      //Undocumented - but using null makes BOUNCE only once..
      animation = 6;
    }
    this.setState((prevState, props) => {
      return { isActive: !prevState, animation: animation };
    });

    // bounce tre ganger
    setTimeout(() => {
      this.setState((prevState, props) => {
        return { animation: 6 };
      });
    }, 1420);
  };
}
