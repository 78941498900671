import { toast } from "react-toastify";

export function Cap(string) {
  if (string !== undefined) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}

export function notifyError(error) {
  console.error(error);
  toast.error(error, {
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true
  });
}

export function notifyWarning(error) {
  console.warn(error);
  toast.warn(error, {
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true
  });
}

export function notifyGenericError(strings, error) {
  notifyError(strings.errorMessageGeneric(error));
}

export function notifyNetworkError(strings, error) {
  notifyError(strings.errorMessageNetworkError(error));
}
