import React, { Component } from "react";
import { API } from "utils/api";
import _ from "lodash";
import * as helpers from "utils/functions";
import Card from "components/Card/Card";
import "./Content.css";
import { Dropdown } from "office-ui-fabric-react/lib/Dropdown";
import { PrimaryButton } from "office-ui-fabric-react/lib/Button";

export default class Groups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groups: this.props.userGroups,
      options: this.props.userGroupsOptions
    };
  }

  componentDidMount = () => {};

  render() {
    const { strings } = this.props;
    return (
      <div>
        <Card header={helpers.Cap(strings.groups)}>
          <div className="row">
            <div className="col">
              <Dropdown
                placeHolder={helpers.Cap(strings.groups)}
                onChanged={this.onClickSelectedItem}
                defaultSelectedKeys={this.props.selectedUserGroupsKeys}
                multiSelect={true}
                multiSelectDelimiter=",  "
                options={this.props.userGroupsOptions}
              />
            </div>
          </div>
          <div className="row">
            <div
              className="col"
              style={{ textAlign: "right", marginTop: "10px" }}
            >
              <PrimaryButton
                text={strings.deleteGroup}
                onClick={this.onDeleteGroupClick}
                disabled={
                  this.props.selectedUserGroupsKeys.length === 1 ? false : true
                }
              />
            </div>
          </div>
        </Card>
      </div>
    );
  }

  onDeleteGroupClick = () => {
    return fetch(
      API + `/api/v1.0/Groups/Delete/${this.props.selectedUserGroupsKeys[0]}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: "bearer " + localStorage.getItem("access_token")
        }
      }
    )
      .then(response => {
        if (response.ok) {
          // Request success
          this.props.loadUserGroups();
          this.props.clearUnitList();
        } else {
          // Request failed
          response.text().then(result => {
            helpers.notifyGenericError(this.props.strings, result);
          });
        }
      })
      .catch(error => {});
  };

  onClickSelectedItem = item => {
    const { selectedUserGroupsKeys } = this.props;
    const units = [];
    let index = _.indexOf(selectedUserGroupsKeys, item.key);

    units.push(
      _.find(this.props.userGroups, o => {
        return o.enhetsGruppPk === item.key;
      })
    );

    if (item.selected) {
      selectedUserGroupsKeys.push(item.key);
      this.props.addUnitsToList(units);
    } else {
      selectedUserGroupsKeys.splice(index, 1);
      this.props.removeUnitsFromList(units);
    }

    selectedUserGroupsKeys.forEach((item, idx) => {
      units.push(
        _.find(this.props.userGroups, o => {
          return o.enhetsGruppPk === item;
        })
      );
    });
    this.props.setSelectedUserGroupKeys(selectedUserGroupsKeys);
  };
}
