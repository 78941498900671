import React, { Component } from "react";
import { connect } from "react-redux";
import { mapDispatchToProps } from "actions/mapDispatchToProps";

import _ from "lodash";
// import { API } from "utils/api";
import * as helpers from "utils/functions";
import Card from "components/Card/Card";
import { Dropdown } from "office-ui-fabric-react/lib/Dropdown";

const mapStateToProps = state => {
  return state;
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  class POI extends Component {
    componentWillMount = () => {
      this.props.loadUserPlaces(this.props.strings);
      this.props.loadUserPOIs(this.props.strings);
    };

    render() {
      const { strings, application } = this.props;
      let { selectedPlaces, selectedPOIs } = application.poi;

      // start unscrupulous meaningless hack
      // Dropdown does not update when referencing application.poi.userPois directly.
      let newPlaces = [];
      let newPois = [];
      application.poi.userPois.map((item, index) => {
        return newPois.push(item);
      });

      application.poi.userPlaces.map((item, index) => {
        return newPlaces.push(item);
      });
      // end unscrupulous meaningless hack

      return (
        <div>
          <Card header={helpers.Cap(strings.poi)}>
            <div className="row">
              <div className="col">
                <Dropdown
                  placeHolder={strings.poiDropdownPlaceHolder}
                  onChanged={option => this.updateSelectedPlace(option.key)}
                  selectedKeys={selectedPlaces}
                  multiSelect={true}
                  multiSelectDelimiter=","
                  options={newPlaces}
                />
              </div>
            </div>
            <div className="row" style={{ marginTop: "15px" }}>
              <div className="col">
                <Dropdown
                  placeHolder={strings.pois}
                  onChanged={option => this.updateSelectedPOI(option.key)}
                  selectedKeys={selectedPOIs}
                  multiSelect={true}
                  multiSelectDelimiter=","
                  options={newPois}
                />
              </div>
            </div>
          </Card>
        </div>
      );
    }

    updateSelectedPlace(key) {
      let store = this.props.application.poi.selectedPlaces;
      let index = _.indexOf(store, key);
      if (index >= 0) {
        _.pullAt(store, index);
        if (store.length === 0) {
          store = [];
        }
      } else {
        store.push(key);
      }

      this.props.updateSelectedPlace(store);
      let item = this.props.application.poi.places.filter(e => e.key === key);

      if (item.length === 1) {
        this.props.updatePlaceInMap(item[0]);
      }
    }

    updateSelectedPOI(key) {
      let store = this.props.application.poi.selectedPOIs;
      let index = _.indexOf(store, key);
      if (index >= 0) {
        _.pullAt(store, index);
        if (store.length === 0) {
          store = [];
        }
      } else {
        store.push(key);
      }
      this.props.updateSelectedPOI(store);

      let item = this.props.application.poi.pois.filter(e => e.poiPk === key);
      if (item.length === 1) {
        this.props.updatePoiInMap(item[0]);
      }
    }
  }
);
