import { API } from "utils/api";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import translate from "i18n/Translate";
import * as helpers from "utils/functions";
import { Modal } from "office-ui-fabric-react/lib/Modal";
import {
  DefaultButton,
  PrimaryButton,
  Dropdown,
  TextField
} from "office-ui-fabric-react";
import "./UserNewRole.css";

export default withRouter(
  translate()(
    class UserNewRoleForm extends Component {
      constructor(props) {
        super(props);
        this.state = {
          visible: true,
          username: "",
          displayEmployeeNumber: false,
          roles: [],
          user: {
            userName: this.props.user.userName,
            name: this.props.user.name,
            roleName: this.props.user.roleName,
            phoneNo: this.props.user.phoneNo,
            email: this.props.user.email,
            akarNo: this.props.user.akarNo,
            customerNo: this.props.user.customerNo,
            employeeNo: this.props.user.employeeNo
          },
          errors: {
            akarNo: "",
            customerNo: "",
            general: "",
            username: "",
            company: "",
            email: "",
            name: "",
            phone: "",
            role: "",
            password: "",
            passwordRetype: ""
          }
        };
      }

      componentDidMount() {
        this.createCompanyDropdownOptions();
      }

      render() {
        const { strings } = this.props;
        return (
          <Modal
            containerClassName="UserNewRoleFormModalContainer ms-scaleUpIn100"
            isOpen={this.state.visible}
            onDismiss={this.onDismiss}
            isBlocking={true}
            scrollableContentClassName="ModalContainerNoScroll"
          >
            <div className="UserNewRoleFormContainer ">
              <div className="UserNewRoleFormHeader">
                <font className="ms-font-xxl">{strings.addRole}</font>
              </div>
              <div className="UserNewRoleFormContent">
                <div className="row">
                  <div className="col-12">
                    <Dropdown
                      label={helpers.Cap(strings.companies)}
                      options={this.state.companies}
                      onChanged={this.onCompanyChange}
                      errorMessage={this.state.errors.company}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <Dropdown
                      label={helpers.Cap(strings.role)}
                      options={this.state.roles}
                      onChanged={this.onRoleChange}
                      errorMessage={this.state.errors.role}
                    />
                  </div>
                </div>
                {this.createRoleCustomField(this.state.role)}
              </div>

              <div className="UserNewRoleFormFooter">
                <DefaultButton
                  className="UserNewRoleFormButton"
                  onClick={this.onDismiss}
                  text={strings.abort}
                />
                <PrimaryButton
                  className="UserNewRoleFormButton"
                  text={strings.save}
                  onClick={this.addRole}
                />
              </div>
            </div>
          </Modal>
        );
      }

      onCompanyChange = value => {
        this.getCurrentRoles(value.key);
        this.setState({ company: value.key });
      };

      onRoleChange = value => {
        this.setState({ role: value.key });
      };

      createRoleCustomField = rolePk => {
        const { strings } = this.props;

        switch (parseInt(rolePk, 10)) {
          case 3: // Bruker
            return (
              <div className="row">
                <div className="col-12">
                  <TextField
                    label={helpers.Cap(strings.employeeNumber)}
                    type="text"
                    onChanged={value => this.setState({ employeeNo: value })}
                  />
                </div>
              </div>
            );

          case 4: // Fører
          case 5: // Fører
          case 7: // Fører
            return (
              <div className="row">
                <div className="col-12">
                  <TextField
                    label={helpers.Cap(strings.driverNumber)}
                    type="text"
                    onChanged={value => this.setState({ akarNo: value })}
                    errorMessage={this.state.errors.akarNo}
                  />
                </div>
              </div>
            );

          case 6: // Kunde
            return (
              <div className="row">
                <div className="col-12">
                  <TextField
                    label={helpers.Cap(strings.customerNumber)}
                    type="text"
                    onChanged={value => this.setState({ customerNo: value })}
                    errorMessage={this.state.errors.customerNo}
                  />
                </div>
              </div>
            );

          default:
            return null;
        }
      };

      addRole = () => {
        var regexIsNotNumber = /[^0-9]/g;
        const errors = [];
        let isValid = true;

        const newRolePayLoad = {
          companyPk: this.state.company,
          userPk: this.props.user.userPk,
          rolePk: this.state.role,
          employeeNo: this.state.employeeNo,
          customerNo: this.state.customerNo,
          akarNo: this.state.akarNo
        };

        if (this.state.akarNo) {
          if (regexIsNotNumber.test(this.state.akarNo)) {
            errors.akarNo = this.props.strings.errorMessageMissingAkarNo;
            isValid = false;
          }
        }

        if (this.state.customerNo) {
          if (regexIsNotNumber.test(this.state.customerNo)) {
            errors.customerNo = this.props.strings.errorMessageMissingCustomerNo;
            isValid = false;
          }
        }

        if (!isValid) {
          this.setState({ errors });
          return;
        }

        return fetch(API + `/api/v1.0/Administrator/AddRole`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "bearer " + localStorage.getItem("access_token")
          },
          body: JSON.stringify(newRolePayLoad)
        })
          .then(response => {
            if (response.ok) {
              // Request success
              this.onDismiss();
              this.props.onSuccessCallback();
            }

            if (!response.ok) {
              // Request failed
              console.error(
                `addRole: (${response.status})  ${response.statusText}`
              );
            }
          })
          .catch(error => {
            this.setState({ error: error });
          });
      };

      createCompanyDropdownOptions = () => {
        const dropdownOptions = [];

        fetch(API + "/api/v1.0/User/Get/UserCompanies", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "bearer " + localStorage.getItem("access_token")
          }
        }).then(response => {
          if (response.ok) {
            // Request success
            response.json().then(result => {
              result.forEach(element => {
                dropdownOptions.push({
                  key: element.companyPk,
                  text: element.companyName
                });
              });
              this.setState({ companies: dropdownOptions });
            });
          } else {
            // Request failed
            response.json().then(result => {});
          }
        });
      };

      getCurrentRoles = value => {
        fetch(API + `/api/v1.0/User/Get/UserRoles/${value}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "bearer " + localStorage.getItem("access_token")
          }
        }).then(response => {
          if (response.ok) {
            // Request success
            response.json().then(result => {
              const roleElements = [];
              result.forEach(element => {
                roleElements.push({
                  key: element.rolePk,
                  text: element.roleName
                });
              });
              this.setState({ roles: roleElements });
            });
          } else {
            // Request failed
            response.json().then(result => {});
          }
        });
      };

      onDismiss = () => {
        this.setState({ visible: false });
        this.props.toggleUserAdministration(true);
      };
    }
  )
);
