import { createStore, compose, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import DevTools from "components/DevTools";
import AppReducers from "reducers/AppReducers";
import AuthenticationReducers from "reducers/AuthenticationReducers";
import LogReducers from "reducers/LogReducers";

const reducers = combineReducers({
  application: AppReducers,
  authentication: AuthenticationReducers,
  log: LogReducers
});

const createStoreWithMiddleware = compose(applyMiddleware(thunk))(createStore);
/*
      primaryColor: "locusPrimary",
      secondaryColor: "locusSecondary"
      */
export const initialState = {
  application: {
    isLoading: true,
    currentLanguage: "sv",
    layout: {
      primaryColor: "locusPrimary",
      secondaryColor: "locusSecondary"
    },
    showUserNotifications: false,
    notifications: {},
    userAdmin: {
      isActive: false
    },
    poi: {
      userPlaces: [],
      userPois: [],
      selectedPlaces: [],
      selectedPOIs: []
    }
  },
  authentication: {
    authRequested: false,
    isAuthenticated: false,
    isPreAuth: false,
    preAuthRequest: false,
    alertExpiration: false,
    userInfo: {
      username: null,
      firstName: null,
      lastName: null
    }
  }
};

const enhancer = compose(DevTools.instrument());

const store = createStoreWithMiddleware(reducers, initialState, enhancer);

export { store };
